import React from "react";

function PartnershipSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M22.3906 9.37304H30.2188L38.8037 0.771047C39.0462 0.526729 39.3348 0.332808 39.6528 0.200471C39.9708 0.0681336 40.3119 0 40.6563 0C41.0008 0 41.3419 0.0681336 41.6598 0.200471C41.9778 0.332808 42.2664 0.526729 42.509 0.771047L49.2412 7.52231C49.7272 8.0107 50 8.67136 50 9.36001C50 10.0487 49.7272 10.7093 49.2412 11.1977L43.2657 17.193H22.3906V22.4064C22.3906 23.0977 22.1157 23.7607 21.6263 24.2496C21.137 24.7384 20.4733 25.013 19.7812 25.013C19.0892 25.013 18.4255 24.7384 17.9361 24.2496C17.4468 23.7607 17.1718 23.0977 17.1718 22.4064V14.5864C17.1718 13.2037 17.7217 11.8777 18.7004 10.9C19.6791 9.9223 21.0065 9.37304 22.3906 9.37304ZM6.73429 22.4064V32.833L0.758791 38.8023C0.27279 39.2907 0 39.9514 0 40.64C0 41.3286 0.27279 41.9893 0.758791 42.4777L7.49101 49.229C7.73359 49.4733 8.02219 49.6672 8.34016 49.7995C8.65814 49.9319 8.9992 50 9.34367 50C9.68814 50 10.0292 49.9319 10.3472 49.7995C10.6652 49.6672 10.9538 49.4733 11.1963 49.229L22.3906 38.0464H32.8282C33.5202 38.0464 34.1839 37.7717 34.6733 37.2829C35.1626 36.794 35.4375 36.131 35.4375 35.4397V32.833H38.0469C38.739 32.833 39.4027 32.5584 39.8921 32.0696C40.3814 31.5807 40.6563 30.9177 40.6563 30.2264V27.6197H43.2657C43.9578 27.6197 44.6215 27.3451 45.1108 26.8562C45.6002 26.3674 45.8751 25.7044 45.8751 25.013V22.4064H27.6094V25.013C27.6094 26.3957 27.0595 27.7217 26.0808 28.6994C25.1021 29.6771 23.7747 30.2264 22.3906 30.2264H17.1718C15.7877 30.2264 14.4603 29.6771 13.4816 28.6994C12.5029 27.7217 11.9531 26.3957 11.9531 25.013V17.193L6.73429 22.4064Z"
        fill="#34B44A"
      />
    </svg>
  );
}

export default PartnershipSvg;
