import React from "react";
import {
  HomeCharge,
  PublicCharging,
  VerteChargeApp,
} from "../Assets/images/imageImport";

function Card({
  bgColor,
  Title1Text,
  Title2Text,
  Title3Text,
  Para1Text,
  Para2Text,
  Para3Text,
  btnText1,
  btnText2,
  btnText3,
  onClick1,
  onClick2,
  onClick3,
}) {
  const DivStyle = `${bgColor} py-[5rem]`;

  return (
    <div className={DivStyle}>
      <div className="sm:w-[90%] w-[95%] mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 md:gap-6 gap-12">
        <div className="shadow-2xl  rounded-[1.875rem] sm:w-[80%] w-[85%] mx-auto md:w-full">
          <div className="flex justify-center">
            <img className="w-full h-auto" src={HomeCharge} alt="Home Charge" />
          </div>
          <div className="p-6 bg-white relative rounded-bl-[1.875rem] h-[13rem] rounded-br-[1.875rem]">
            <h3 className="font-bold text-headingColor text-xl leading-6 CardHeadingText">
              {/* Home Charging */}
              {Title1Text}
            </h3>
            <p className="font-normal lg:text-[1rem] text-[17px] text-textColor lg:leading-5 leading:6 mt-2 mb-4 CardSubText">
              {/* Make your home charging experience smart and secure with our AC
              home charger */}
              {Para1Text}
            </p>
            <div className="flex justify-end">
              <button onClick={onClick1} className="absolute bottom-5 items-center bg-primaryColor font-bold  border-1 py-[10px] px-[20px] focus:outline-none text-white rounded-[10px] sm:text-base text-sm sm:mt-4 md:mt-0 CardBtn">
                {btnText1}
              </button>
            </div>
          </div>
        </div>

        <div className="shadow-2xl rounded-[1.875rem] sm:w-[80%] w-[85%] mx-auto md:w-full">
          <div className="flex justify-center">
            <img
              className="w-full h-auto"
              src={PublicCharging}
              alt="PublicCharging"
            />
          </div>
          <div className="p-6 relative h-[13rem] bg-white rounded-bl-[1.875rem] rounded-br-[1.875rem]">
            <h3 className="font-bold text-headingColor text-xl leading-6 CardHeadingText">
              {/* Public Charging */}
              {Title2Text}
            </h3>
            <p className="font-normal lg:text-[1rem] text-[17px] text-textColor lg:leading-5 leading-6 mt-2 mb-4 CardSubText">
              {/* Access the biggest integrated public charging network to make
              booking and payments stress free */}
              {Para2Text}
            </p>
            <div className="flex justify-end">
              <button onClick={onClick2} className="absolute bottom-5 items-center bg-primaryColor font-bold  border-1 py-[10px] px-[20px] focus:outline-none text-white rounded-[10px] sm:text-base text-sm sm:mt-4 md:mt-0 CardBtn">
                {btnText2}
              </button>
            </div>
          </div>
        </div>

        <div className="shadow-2xl rounded-[1.875rem] sm:w-[80%] w-[85%] mx-auto md:w-1/2 lg:w-full md:col-span-2 lg:col-span-1">
          <div className="flex justify-center">
            <img
              className="w-full h-auto"
              src={VerteChargeApp}
              alt="VerteChargeApp"
            />
          </div>
          <div className="p-6 h-[13rem] relative bg-white rounded-bl-[1.875rem] rounded-br-[1.875rem]">
            <h3 className="font-bold text-headingColor text-xl leading-6 CardHeadingText">
              {/* VERTE Charge Mobile App */}
              {Title3Text}
            </h3>
            <p className="font-normal lg:text-[1rem] text-[17px] text-textColor lg:leading-5 leading-6 mt-2 mb-4 CardSubText">
              {/* Get an all in one home and public charging solution with the verte
              app. Download now! */}
              {Para3Text}
            </p>
            <div className="flex justify-end">
              <button onClick={onClick3} className="absolute sm:bottom-5 bottom-4 items-center bg-primaryColor font-bold  border-1 py-[10px] px-[20px] focus:outline-none text-white rounded-[10px] sm:text-base text-sm sm:mt-4 md:mt-0 CardBtn">
                {btnText3}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
