import React, { useState } from "react";
import {
  whiteLabelBrandImage,
  whiteLabelImage,
  whiteLabelTailorImage,
} from "../Features/Assets/images/imageImport";
import "../Custom-CSS/whiteLabel.css";
import { WhiteLabelLaptop } from "../Features/Assets/SvgIcons";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function WhiteLabels() {
  // States
  const [email, setEmail] = useState("");

  // use for the navigation
  const navigate = useNavigate();

  // BaseUrl
  const BASE_URL = process.env.REACT_APP_RESERVATION_URL;

  // Check if the email is validate
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  // function triggers on the lets talk button
  const handleLetsTalk = async () => {
    if (email) {
      if (validateEmail(email)) {
        try {
          const response = await axios.get(
            `${BASE_URL}/email/subscribe?email=${email}`
          );
          if (response.status === 200) {
            navigate("/lets-talk");
          }
        } catch (error) {
          throw error;
        }
      } else {
        alert("Enter the correct email");
      }
    } else {
      alert("Enter you email first");
    }
  };

  // function to handle the email
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };



  return (
    <div className="">
      <div>
        {/* White Label Section */}
        <div className="flex justify-center my-12">
          <div className="w-[90%]">
            <p className="text-lg text-primaryColor text-center font-semibold">
              White Label
            </p>
            <h1 className="text-center font-semibold text-2xl sm:text-3xl md:text-4xl lg:text-5xl mt-3 text-whiteLabelText">
              Empower Your Brand Journey with <br />
              <span className="text-primaryColor">Verte Charge</span>
            </h1>
            <p className="text-whiteLabelGrayText text-center mt-6 mb-10 font-normal text-base sm:text-lg md:text-xl">
              Unleash our Features, Shape Your Services and Make it Uniquely
              Yours
            </p>
            <div className="flex flex-col items-center">
              <div className="sm:w-auto w-full">
                <div className="flex sm:gap-4 gap-2">
                  <div className="w-full sm:w-[22.5rem] rounded-lg border border-[#D0D5DD] overflow-hidden">
                    <input
                      className="h-full w-full focus:outline-none px-4 py-3"
                      type="text"
                      value={email}
                      onChange={handleEmail}
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="flex justify-center sm:mt-0">
                    <button
                      onClick={handleLetsTalk}
                      className="flex items-center justify-center rounded-lg h-auto w-[7rem] bg-primaryColor"
                    >
                      <p className="font-semibold sm:text-base text-[0.875rem] leading-6 py-3 text-white">
                        Let's Talk
                      </p>
                    </button>
                  </div>
                </div>

                <p className="text-whiteLabelGrayText font-normal mt-2 text-sm">
                  Get in touch with us for white label
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Why Verte Charge White Label */}
        <div className="flex justify-center bg-[#F9FAFB]">
          <div className="w-[90%] py-12 md:py-24">
            <div className="grid md:grid-cols-2 gap-12 md:gap-8">
              <div className="flex justify-center md:justify-end xl:block mb-8 md:mb-0">
                <img
                  className="max-w-full h-auto object-fill"
                  src={whiteLabelImage}
                  alt="White Label Image"
                />
              </div>
              <div>
                <p className="font-semibold text-3xl sm:text-4xl md:text-5xl mb-8 md:mb-12 leading-tight md:leading-[3.75rem] text-whiteLabelText">
                  Why Verte Charge White Label?
                </p>
                <div className="grid lg:grid-cols-2 gap-8">
                  <div className="flex flex-col gap-3">
                    <p className="text-2xl font-semibold leading-tight text-primaryColor">
                      Tailored Branding for Diverse Needs
                    </p>
                    <p className="text-whiteLabelText font-medium sm:text-sm text-base leading-relaxed">
                      Reach new heights with our scalable and adaptable white
                      label solution for smart EV ecosystem management.
                    </p>
                  </div>
                  <div className="flex flex-col gap-3">
                    <p className="text-2xl font-semibold leading-tight text-primaryColor">
                      Seamless Integration
                    </p>
                    <p className="text-whiteLabelText font-medium sm:text-sm text-base leading-relaxed">
                      Effortlessly blend our white label into your existing
                      offerings.
                    </p>
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 my-10 gap-8">
                  <div className="flex flex-col gap-3">
                    <p className="text-2xl font-semibold leading-tight text-primaryColor">
                      Craft Your Identity
                    </p>
                    <p className="text-whiteLabelText font-medium sm:text-sm text-base leading-relaxed">
                      Infuse your branding elements - logos, colours, and
                      messaging - into every aspect of the mobile app, admin
                      portal, and customer interfaces for a seamless, tailored
                      experience.
                    </p>
                  </div>
                  <div className="flex flex-col gap-3">
                    <p className="text-2xl font-semibold leading-tight text-primaryColor">
                      Forge Lasting Bonds
                    </p>
                    <p className="text-whiteLabelText font-medium sm:text-sm text-base leading-relaxed">
                      Cultivate trust and loyalty with a personalised, branded
                      experience that resonates with your customers.
                    </p>
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 gap-8">
                  <div className="flex flex-col gap-3">
                    <p className="text-2xl font-semibold leading-tight text-primaryColor">
                      Own Your Presence
                    </p>
                    <p className="text-whiteLabelText font-medium sm:text-sm text-base leading-relaxed">
                      Publish EV driver apps under your Google Play and App
                      Store accounts, reinforcing your brand's presence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile App Use Cases */}
        <div className="bg-[#333333] flex justify-center">
          <div className="grid md:grid-cols-2 w-[90%] mt-[-5rem] mb-20 md:my-[11.7rem] gap-12 md:gap-0">
            <div className="order-2 md:order-1  md:mt-0 mt-[-5rem]">
              <h1 className="font-semibold text-3xl md:text-4xl leading-tight md:leading-[3.75rem] text-[#ffffff]">
                Mobile App Use Cases
              </h1>
              <div className="grid lg:grid-cols-2 mt-8 md:mt-[4rem] gap-8">
                <div>
                  <h3 className="text-[#ADFFBB] font-semibold text-2xl sm:text-[1.5rem] leading-tight sm:leading-[1.9rem]">
                    Navigation
                  </h3>
                  <p className="text-[1rem] sm:text-[1rem] leading-snug sm:leading-[1.3rem] text-[#ffffff] font-medium">
                    Find nearest charging stations with real-time information on
                    charger types, availability, and bookings.
                  </p>
                </div>
                <div>
                  <h3 className="text-[#ADFFBB] font-semibold text-2xl sm:text-[1.5rem] leading-tight sm:leading-[1.9rem]">
                    Reservation
                  </h3>
                  <p className="text-[1rem] sm:text-[1rem] leading-snug sm:leading-[1.3rem] text-[#ffffff] font-medium">
                    Reserve spots for time-saving charging.
                  </p>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 my-10 md:my-[2.5rem] gap-8">
                <div>
                  <h3 className="text-[#ADFFBB] font-semibold text-2xl sm:text-[1.5rem] leading-tight sm:leading-[1.9rem]">
                    Automated Payment Gateway
                  </h3>
                  <p className="text-[1rem] sm:text-[1rem] leading-snug sm:leading-[1.3rem] text-[#ffffff] font-medium">
                    Hassle-free transactions via mobile wallet, cards, or QR
                    code scanning.
                  </p>
                </div>
                <div>
                  <h3 className="text-[#ADFFBB] font-semibold text-2xl sm:text-[1.5rem] leading-tight sm:leading-[1.9rem]">
                    Smart Car
                  </h3>
                  <p className="text-[1rem] sm:text-[1rem] leading-snug sm:leading-[1.3rem] text-[#ffffff] font-medium">
                    Control your ride anywhere, anytime with our unique
                    features.
                  </p>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 gap-8">
                <div>
                  <h3 className="text-[#ADFFBB] font-semibold text-2xl sm:text-[1.5rem] leading-tight sm:leading-[1.9rem]">
                    Charge Now
                  </h3>
                  <p className="text-[1rem] sm:text-[1rem] leading-snug sm:leading-[1.3rem] text-[#ffffff] font-medium">
                    Charge and Pay by scanning QR code.
                  </p>
                </div>
                <div>
                  <h3 className="text-[#ADFFBB] font-semibold text-2xl sm:text-[1.5rem] leading-tight sm:leading-[1.9rem]">
                    Charging Analytics
                  </h3>
                  <p className="text-[1rem] sm:text-[1rem] leading-snug sm:leading-[1.3rem] text-[#ffffff] font-medium">
                    Analyse active charging sessions, bookings, and transaction
                    history.
                  </p>
                </div>
              </div>
            </div>
            <div className="order-1 md:order-2  md:mt-0 mt-[3rem]">
              <div className="flex justify-center relative">
                <img
                  className="lg:max-w-[47rem] lg:max-h-[58.875rem] max-w-full h-auto md:absolute lg:top-[-12rem] right-[-5rem] lg:right-0 mobileImage "
                  src={require("../../src/Features/Assets/images/whiteLabelMobile.png")}
                  alt="whiteLabelMobileImage"
                />
              </div>
            </div>
          </div>
        </div>

        {/* PlatForm Use Cases */}
        <div className="flex justify-center">
          <div className="md:py-[5rem] pt-[-15rem] w-[90%]">
            <div className="grid xl:grid-cols-2 gap-[6rem]">
              <div className="relative">
                <div className="xl:absolute left-[-15rem] top-[-7rem]">
                  <WhiteLabelLaptop />
                </div>
              </div>
              <div className="md:mt-0 mt-[-4rem]">
                <p className="font-semibold text-4xl md:text-5xl mb-12 leading-[3.75rem] text-whiteLabelText">
                  Platform Use Cases
                </p>
                <div className="grid lg:grid-cols-2 gap-8">
                  <div>
                    <div className="flex flex-col gap-3">
                      <p className="text-2xl font-semibold leading-[1.9rem] text-primaryColor">
                        Dashboard
                      </p>
                      <p className="text-whiteLabelText font-medium text-[1rem] leading-[1.28rem]">
                        Centralised dashboard with key metrics and performance
                        indicators
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col gap-3">
                      <p className="text-2xl font-semibold leading-[1.9rem] text-primaryColor">
                        Stations and Booking Managements
                      </p>
                      <p className="text-whiteLabelText font-medium text-[1rem] leading-[1.28rem]">
                        Manage charging stations and reservations efficiently
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 my-10 gap-8">
                  <div>
                    <div className="flex flex-col gap-3">
                      <p className="text-2xl font-semibold leading-[1.9rem] text-primaryColor">
                        Analyse Insights
                      </p>
                      <p className="text-whiteLabelText font-medium text-[1rem] leading-[1.28rem]">
                        Track top revenue stations, revenue heatmaps, peak
                        usage, & charging efficiency metrics and monitor
                        revenue 
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col gap-3">
                      <p className="text-2xl font-semibold leading-[1.9rem] text-primaryColor">
                        Customer Engagement
                      </p>
                      <p className="text-whiteLabelText font-medium text-[1rem] leading-[1.28rem]">
                        Ensure station facilities, gather user feedback for
                        service improvement; implement targeted advertising.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 gap-8">
                  <div className="flex flex-col gap-3">
                    <p className="text-2xl font-semibold leading-[1.9rem] text-primaryColor">
                      Security and Compliance
                    </p>
                    <p className="text-whiteLabelText font-medium text-[1rem] leading-[1.28rem]">
                      Ensure data security and scalability for future growth.
                    </p>
                  </div>
                  <div>
                    <p className="text-2xl font-semibold leading-[1.9rem] text-primaryColor">
                      Scalability and Multi-Station Management
                    </p>
                    <p className="text-whiteLabelText font-medium text-[1rem] leading-[1.28rem]">
                      Expand your business and reap the advantages of a unified
                      user interface
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Who can benefit? */}
        <div className="flex justify-center bg-[#F9FAFB]">
          <div className="w-[90%]">
            <div className="grid lg:grid-cols-2 gap-[2rem] lg:gap-[4rem] xl:gap-[6rem] my-[4rem] lg:my-[7.75rem]">
              <div>
                <div>
                  <h1 className="font-semibold text-3xl sm:text-[2.5rem] lg:text-[3rem] leading-tight lg:leading-[3.75rem] text-[#333333]">
                    Who can benefit?
                  </h1>
                  <p className="font-normal text-2xl sm:text-[1.25rem] leading-relaxed lg:leading-[1.875rem] text-[#667085] mt-3">
                    Verte Charge optimises white labelling, guaranteeing
                    accessibility and cost-effectiveness across all industries.
                  </p>
                </div>
                <div className="mt-[2rem] lg:mt-[3rem]">
                  <div>
                    <h5 className="font-semibold text-2xl sm:text-[1.5rem] leading-snug lg:leading-[1.875rem] text-primaryColor">
                      Charge Point Operators (CPOs)
                    </h5>
                    <p className="font-medium text-[1rem] sm:text-[1rem] leading-snug mt-3 text-[#333333]">
                      Leverage VerteCharge for driving the expansion of your EV
                      charging Network
                    </p>
                  </div>
                  <div className="my-[1.5rem] lg:my-[2.5rem]">
                    <h5 className="font-semibold text-2xl sm:text-[1.5rem] leading-snug lg:leading-[1.875rem] text-primaryColor">
                      Oil Marketing Companies (OMCs)
                    </h5>
                    <p className="font-medium text-[1rem] sm:text-[1rem] leading-snug mt-3 text-[#333333]">
                      Streamline the management of stations and enable cashless
                      payments through app
                    </p>
                  </div>
                  <div>
                    <h5 className="font-semibold text-2xl sm:text-[1.5rem] leading-snug lg:leading-[1.875rem] text-primaryColor">
                      Corporate Entities
                    </h5>
                    <p className="font-medium text-[1rem] sm:text-[1rem] leading-snug mt-3 text-[#333333]">
                      Employ Verte Charge White Label Solution to serve multiple
                      needs within your business
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center relative mt-[2rem] lg:mt-0">
                <img
                  className="max-w-full h-auto xl:absolute lg:top-[-3rem] xl:top-[-5rem]"
                  src={whiteLabelBrandImage}
                  alt="whiteLabelBrandImage"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Tailor-Made Selection */}
        <div className="bg-[#333333] pt-12 flex justify-center">
          <div className="w-[90%]">
            <div className="flex flex-col items-center">
              <h1 className="font-semibold text-3xl sm:text-[2.5rem] md:text-[3rem] leading-tight md:leading-[3.75rem] text-[#ffffff] text-center">
                Tailor-Made Selection
              </h1>
              <p className="font-normal text-2xl sm:text-[1.25rem] leading-[1.5rem] md:leading-[1.875rem] text-[#ffffff] mt-3 text-center">
                Customize your ideal services
              </p>
            </div>
            <div className="mt-[1.5rem] flex flex-col items-center">
              <h3 className="font-semibold text-2xl sm:text-[1.5rem] leading-[1.75rem] sm:leading-[1.875rem] text-[#AEFFBB] text-center">
                Platform Solutions
              </h3>
              <p className="font-medium text-[1rem] sm:text-[1rem] leading-snug sm:leading-[1.228125rem] text-[#ffffff] mt-3 text-center">
                Power your Business with our Cutting-Edge Platform and
                Dashboard!
              </p>
            </div>
            <div className="my-[2rem] sm:my-[3rem] flex flex-col items-center">
              <h3 className="font-semibold text-2xl sm:text-[1.5rem] leading-[1.75rem] sm:leading-[1.875rem] text-[#AEFFBB] text-center">
                Mobile App
              </h3>
              <p className="font-medium text-[1rem] sm:text-[1rem] leading-snug sm:leading-[1.228125rem] text-[#ffffff] mt-3 text-center">
                A State of the Art, Fully Customizable App with Intuitive User
                Interface
              </p>
            </div>
            <div className="flex flex-col items-center">
              <h3 className="font-semibold text-2xl sm:text-[1.5rem] leading-[1.75rem] sm:leading-[1.875rem] text-[#AEFFBB] text-center">
                Web App
              </h3>
              <p className="font-medium text-[1rem] sm:text-[1rem] leading-snug sm:leading-[1.228125rem] text-[#ffffff] mt-3 text-center">
                Charge Up Your Brand with a Seamless, Branded Interface!
              </p>
            </div>
            <div className="mt-[1.5rem] flex justify-center">
              <img
                className="max-w-full h-auto"
                src={whiteLabelTailorImage}
                alt="whiteLabelTailorImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhiteLabels;
