import React from "react";

function PlusSvg({ onClick, mediaControl }) {
  return (
    <svg
      className={mediaControl}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 41 41"
      fill="none"
      onClick={onClick}
    >
      <path d="M38 20.5H3H38ZM20.5 3V38V3Z" fill="#34B44A" />
      <path
        d="M38 20.5H3M20.5 3V38"
        stroke="#34B44A"
        stroke-width="5.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default PlusSvg;
