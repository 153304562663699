import {
  Home,
  EvUsers,
  StationOwners,
  HomeCharging,
  ChargingStations,
  PrivacyPolicy,
  FleetManagement,
  DeleteAccount,
  AppReviewPage,
  WhiteLabels,
  PaymentGatewayPolicy,
  PricingPolicy,
  RefundPolicy,
  TermsOfUse,
} from "./Pages";
import { LetsTalk } from "./Lets-Talk";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CustomNavbar, Footer, ScrollToTop } from "../src/Features/UI";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <ScrollToTop />
        <CustomNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ev-users" element={<EvUsers />} />
          <Route path="/station-owners" element={<StationOwners />} />
          <Route path="/home-charging" element={<HomeCharging />} />
          <Route path="/charging-stations" element={<ChargingStations />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/fleet-management" element={<FleetManagement />} />
          <Route path="/white-labels" element={<WhiteLabels />} />
          <Route path="/lets-talk" element={<LetsTalk />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/app-review" element={<AppReviewPage />} />
          <Route path="/payment-policy" element={<PaymentGatewayPolicy />} />
          <Route path="/pricing-policy" element={<PricingPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
        </Routes>
        <Footer />
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
