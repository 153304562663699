import { useEffect } from "react";

const AppReviewPage = () => {
  useEffect(() => {
    console.log('here')
    // Redirect to the specified URL in a new tab
    const redirectUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLSfe_AHAhq_vrH5miV0BUR1vcK1gIg9zofb8WNUrjkm2fC_SIQ/viewform";
    window.open(redirectUrl, "_blank");

    // Optionally, you can close the current tab after redirection
    // window.close();
  }, []);

  // This component doesn't render anything since it redirects immediately

  return null;
};

export default AppReviewPage;
