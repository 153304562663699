import React from "react";

function PhoneSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 38 39"
      fill="none"
    >
      <path
        d="M33.7778 19.446C33.7778 15.2327 32.3436 11.6589 29.4753 8.72476C26.607 5.79058 23.1153 4.32277 19 4.32133V0C21.6389 0 24.111 0.513518 26.4163 1.54055C28.7217 2.56759 30.7272 3.95402 32.433 5.69983C34.1388 7.44565 35.4934 9.49828 36.4969 11.8577C37.5004 14.2172 38.0014 16.7466 38 19.446H33.7778ZM25.3333 19.446C25.3333 17.6454 24.7176 16.115 23.4861 14.8546C22.2546 13.5942 20.7593 12.964 19 12.964V8.64266C21.9204 8.64266 24.4101 9.69634 26.4691 11.8037C28.5281 13.9111 29.557 16.4585 29.5556 19.446H25.3333ZM35.7833 39C31.3852 39 27.0307 38.0277 22.7198 36.0831C18.4089 34.1385 14.4949 31.3657 10.9778 27.7645C7.45926 24.1634 4.75 20.1662 2.85 15.7729C0.95 11.3795 0 6.91413 0 2.37673V0.108033H12.4556L14.4083 10.9654L8.39167 17.1773C9.16574 18.5817 10.0278 19.9141 10.9778 21.1745C11.9278 22.4349 12.9481 23.6053 14.0389 24.6856C15.0593 25.7299 16.1767 26.7296 17.3913 27.6846C18.6059 28.6396 19.9162 29.5305 21.3222 30.3573L27.4444 24.0914L38 26.3061V39H35.7833Z"
        fill="#34B44A"
      />
    </svg>
  );
}

export default PhoneSvg;
