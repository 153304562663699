import React from "react";

function MarkerSvg({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="37"
      viewBox="0 0 37 49"
      fill="none"
    >
      <path
        d="M36.0965 18.0482C36.0965 33.5809 24.9623 44.3098 20.184 48.1985C18.9403 49.2105 17.1552 49.2105 15.9121 48.1985C11.1342 44.3098 0 33.5809 0 18.0482C0 8.08049 8.08049 0 18.0482 0C28.016 0 36.0965 8.08049 36.0965 18.0482Z"
        fill={color ||"#34B44A"}
      />
      <path
        d="M18.0475 31.0376C25.2189 31.0376 31.0325 25.224 31.0325 18.0526C31.0325 10.8812 25.2189 5.06763 18.0475 5.06763C10.8761 5.06763 5.0625 10.8812 5.0625 18.0526C5.0625 25.224 10.8761 31.0376 18.0475 31.0376Z"
        fill="white"
      />
      <path
        d="M19.9201 9.71167H13.6272C12.5001 9.71167 11.5859 10.6258 11.5859 11.7529V24.3062H21.9609V11.7529C21.9609 10.6258 21.0467 9.71167 19.9197 9.71167H19.9201ZM20.3878 16.3174H13.1595V12.2363H20.3878V16.3174Z"
        fill="#375133"
      />
      <path
        d="M16.3909 12.7549L15.7871 14.5937H16.6706L16.2197 15.9731L17.7612 13.9271H17.013L17.5449 12.7549H16.3909Z"
        fill="#375133"
      />
      <path
        d="M22.9991 24.7903H10.5488V26.243H22.9991V24.7903Z"
        fill="#375133"
      />
      <path
        d="M27.2468 12.8015H24.0703V13.3166H27.2468V12.8015Z"
        fill="#375133"
      />
      <path
        d="M24.3716 13.5742H26.9471V14.7344C26.9471 15.0893 26.6588 15.3776 26.3039 15.3776H25.0143C24.6593 15.3776 24.3711 15.0893 24.3711 14.7344V13.5742H24.3716Z"
        fill="#375133"
      />
      <path
        d="M24.8868 10.7837C25.0173 10.7837 25.1228 10.8897 25.1228 11.0197V12.5434H24.6504V11.0197C24.6504 10.8892 24.7564 10.7837 24.8864 10.7837H24.8868Z"
        fill="#375133"
      />
      <path
        d="M26.4337 10.7837C26.5642 10.7837 26.6697 10.8897 26.6697 11.0197V12.5434H26.1973V11.0197C26.1973 10.8892 26.3033 10.7837 26.4332 10.7837H26.4337Z"
        fill="#375133"
      />
      <path
        d="M24.5839 21.8161C23.7974 21.8161 23.1576 21.1763 23.1576 20.3898V18.4915C23.1576 18.0996 22.8391 17.7812 22.4473 17.7812V17.1802C23.1705 17.1802 23.7585 17.7687 23.7585 18.4915V20.3898C23.7585 20.8449 24.1288 21.2152 24.5839 21.2152C25.0391 21.2152 25.4093 20.8449 25.4093 20.3898V15.7634H26.0103V20.3898C26.0103 21.1763 25.3705 21.8161 24.5839 21.8161Z"
        fill="#375133"
      />
    </svg>
  );
}

export default MarkerSvg;
