import React from "react";

function LighteningSvg({height, width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "15"}
      height={height || "33"}
      viewBox="0 0 28 46"
      fill="none"
    >
      <path
        d="M12.9958 16.4142L12.2918 17.8536H13.8941H25.4005L21.4368 21.745L21.4288 21.7528L21.421 21.7609L3.64927 40.0077L12.0251 22.8983L12.7299 21.4586H11.1269H2.60808L6.42439 17.5524L6.4252 17.5516L18.4966 5.16766L12.9958 16.4142Z"
        fill="#34B44A"
        stroke="#34B44A"
        stroke-width="2"
      />
    </svg>
  );
}

export default LighteningSvg;
