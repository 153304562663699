import React from "react";
import { SEO } from "../Features/UI";

const DeleteAccount = () => {
  return (
    <div className="bg-sectionBgColor">
      <SEO
        title="VerteCharge: Delete Account"
        description="Fill in the form details to process account deletion"
        name="VerteCharge"
        type="article"
        image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
        canonicalUrl={"https://vertecharge.com/delete-account"}
      />
      {
        <div className="flex items-center justify-center">
          <div className="no-scrollbar">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdHKOzE3X3lfG7tnUtJFVhTLFSmUOTcjkoyb9vF7xhffJMyXA/viewform?embedded=true"
              scrolling="no"
              className="w-100 h-[1941px] sm:h-[1371px] sm:w-[640px]"
              title="Delete Verte Charge Account Form"
            >
              Loading…
            </iframe>
          </div>
        </div>
      }
    </div>
  );
};

export default DeleteAccount;
