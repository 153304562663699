import React, { useState } from "react";
import SelectChargingPlan from "./SelectChargingPlan";
import HomeQuestion from "./HomeQuestion";
import Form from "./Form";
import BusinessQuestion from "./BusinessQuestion";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { Loader, Modal } from "../Features/UI";

function LetsTalk() {
  const [ShowCard, setShowCard] = useState(true);
  const [ShowHomeQuestion, setShowHomeQuestion] = useState(false);
  const [ShowBusinessQuestion, setShowBusinessQuestion] = useState(false);
  const [ShowHQForm, setShowHQForm] = useState(false);
  const [ShowBQForm, setShowBQForm] = useState(false);

  const [charging_type, setCharging_type] = useState(null);
  const [HQFormData, setHQFormData] = useState(null);
  const [BQFormData, setBQFormData] = useState(null);
  const [DetailsFormData, setDetailsFormData] = useState(null);

  // Loader
  const [showLoader, setShowLoader] = useState(false);

  // Modal
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  // Modal Function
  const HandleModalButton = () => {
    // setShowModal(false);
    // setShowCard(true);
    // window.location.reload();
    navigate("/");
  };

  const handleHQFormData = (value) => {
    setHQFormData(value);
  };

  const handleBQFormData = (value) => {
    setBQFormData(value);
  };
  const handleDetailsFormData = (value) => {
    setDetailsFormData(value);
  };

  const HandleForEvDriver = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowCard(false);
    setShowHomeQuestion(true);
    setCharging_type("home_charging");
  };

  const HandleHomeQuestion = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowHQForm(true);
    setShowHomeQuestion(false);
  };

  const HandleHomeQBackButton = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowHomeQuestion(false);
    setShowCard(true);
  };

  const HFBackButton = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowHomeQuestion(true);
    setShowHQForm(false);
  };

  const BQFBackButton = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowBusinessQuestion(true);
    setShowBQForm(false);
  };

  const HandleForBusiness = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowCard(false);
    setShowBusinessQuestion(true);
    setCharging_type("business_charging");
  };
  const HandleDCCharging = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowCard(false);
    setShowBusinessQuestion(true);
    setCharging_type("dc_fast_charging");
  };

  const HandleBQBackButton = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowBusinessQuestion(false);
    setShowCard(true);
  };

  const HandleBQNext = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowBusinessQuestion(false);
    setShowBQForm(true);
  };

  const BASE_URL = process.env.REACT_APP_RESERVATION_URL;
  const chargingType = charging_type;
  const requestBody = {
    electric_vehicle: {
      make: HQFormData ? HQFormData.make : "",
      model: HQFormData ? HQFormData.model : "",
    },
    professional_installation: HQFormData
      ? HQFormData.professional_installation
      : "",
    reimbursement: HQFormData ? HQFormData.reimbursement : "",

    preferred_power_output: BQFormData ? BQFormData.preferred_power_output : "",
    charging_stations_number: BQFormData
      ? BQFormData.charging_stations_number
      : "",
    verte_charge_plans: BQFormData ? BQFormData.verte_charge_plans : "",
    charging_stations_place: BQFormData
      ? BQFormData.charging_stations_place
      : "",
    purchase_timeline: BQFormData ? BQFormData.purchase_timeline : "",

    name: DetailsFormData ? DetailsFormData.name : "",
    phone: DetailsFormData ? DetailsFormData.phone : "",
    email: DetailsFormData ? DetailsFormData.email : "",
    message: DetailsFormData ? DetailsFormData.message : "",
  };

  const postData = async () => {
    try {
      setShowLoader(true);
      const response = await axios.post(
        `${BASE_URL}/lets-talk?charging_type=${chargingType}`,
        requestBody
      );

      // Handle response.data as needed
      if (response.status === 200) {
        setShowModal(true);
        setShowLoader(false);
      }
      // return response.message;
    } catch (error) {
      alert(error.response.data.error);

      // throw error; // Rethrow the error if needed
    } finally{
      setShowLoader(false);
    }
  };

  return (
    <>
      <SelectChargingPlan
        ShowCard={ShowCard}
        onClick1={HandleForEvDriver}
        onClick2={HandleForBusiness}
        onClick3={HandleDCCharging}
      />
      <HomeQuestion
        onClick={HandleHomeQuestion}
        HomeQustionBackButton={HandleHomeQBackButton}
        ShowHomeQuestion={ShowHomeQuestion}
        HQFormData={handleHQFormData}
      />
      <BusinessQuestion
        ShowBusinessQuestion={ShowBusinessQuestion}
        BQBackButton={HandleBQBackButton}
        BQNext={HandleBQNext}
        BQFormData={handleBQFormData}
      />
      <Form
        formtype={"HomeQuestionForm"}
        HFBackButton={HFBackButton}
        ShowForm={ShowHQForm}
        DetailsFormData={handleDetailsFormData}
        PostRequest={postData}
      />
      <Form
        formtype={"BusinessQuestionForm"}
        BQFBackButton={BQFBackButton}
        ShowForm={ShowBQForm}
        DetailsFormData={handleDetailsFormData}
        PostRequest={postData}
      />
      <Modal showModal={showModal} onClickModal={HandleModalButton} />
      <Loader showLoader={showLoader} />
    </>
  );
}

export default LetsTalk;
