import React from "react";

function MapCardPointerSvg2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      viewBox="0 0 21 24"
      fill="none"
    >
      <path
        d="M10.2201 0.603516C12.8691 0.603516 15.4095 1.63432 17.2825 3.46915C19.1556 5.30399 20.2079 7.79256 20.2079 10.3874C20.2079 14.4379 17.0562 18.682 10.886 23.2152C10.6939 23.3563 10.4603 23.4326 10.2201 23.4326C9.98003 23.4326 9.74639 23.3563 9.5543 23.2152C3.3841 18.682 0.232422 14.4379 0.232422 10.3874C0.232422 7.79256 1.2847 5.30399 3.15776 3.46915C5.03082 1.63432 7.57124 0.603516 10.2201 0.603516ZM10.2201 7.12611C9.33718 7.12611 8.49037 7.46971 7.86602 8.08132C7.24166 8.69294 6.89091 9.52246 6.89091 10.3874C6.89091 11.2524 7.24166 12.0819 7.86602 12.6935C8.49037 13.3051 9.33718 13.6487 10.2201 13.6487C11.1031 13.6487 11.9499 13.3051 12.5743 12.6935C13.1986 12.0819 13.5494 11.2524 13.5494 10.3874C13.5494 9.52246 13.1986 8.69294 12.5743 8.08132C11.9499 7.46971 11.1031 7.12611 10.2201 7.12611Z"
        fill="#34B44A"
      />
    </svg>
  );
}

export default MapCardPointerSvg2;
