import React from "react";

function SpoonFolkSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 51 51"
      fill="none"
    >
      <path
        d="M20.8419 23.7596L21.482 23.4492L22.8047 25.8543L25.0662 22.8286L20.6286 16.7385L13.8441 7.31235C13.5454 6.88565 12.8627 6.7305 12.3933 7.00203C10.9426 7.77784 10.3452 9.36824 10.9425 10.7647L15.8496 22.1692C16.5749 23.9923 18.9645 24.7293 20.8419 23.7596Z"
        fill="#34B44A"
      />
      <path
        d="M29.1197 28.415L26.6875 32.9535L31.7225 42.1857C32.4906 43.5822 34.368 44.1252 35.8615 43.3494L36.1175 43.1943C37.6109 42.4184 38.0803 40.6341 37.0989 39.354L29.1197 28.415Z"
        fill="#34B44A"
      />
      <path
        d="M40.8094 10.1804C40.3827 9.83127 39.7426 9.94764 39.4439 10.3743C39.4439 10.3743 35.6037 16.1542 34.4943 17.7834C34.3236 18.0161 33.9822 18.0937 33.7262 17.9773C33.4702 17.8222 33.3422 17.5118 33.5129 17.2791C34.5369 15.5723 38.0785 9.67612 38.0785 9.67612C38.3345 9.24942 38.1212 8.70635 37.5665 8.5124C37.1825 8.39602 36.7558 8.5512 36.5424 8.86152L31.5927 16.2705C31.4221 16.5033 31.0807 16.5808 30.8247 16.4645C30.5686 16.3093 30.4406 15.999 30.6113 15.7662C31.6354 14.0595 35.177 8.1633 35.177 8.1633C35.433 7.7366 35.2196 7.19353 34.6649 6.99958C34.2809 6.8832 33.8542 7.03835 33.6409 7.34868L28.3925 15.1844L27.7525 16.2317C27.0698 17.2791 26.8564 18.5204 27.1551 19.4901L27.7951 21.5848L14.6102 39.3121C13.6714 40.631 14.0982 42.3766 15.5916 43.1524L15.8476 43.3076C17.3411 44.0834 19.2612 43.5791 19.9866 42.1439L30.27 22.9037L32.5741 22.3607C33.6835 22.0891 34.7076 21.3133 35.3903 20.266L36.0304 19.2186L40.852 11.189C41.1934 10.8398 41.1507 10.4131 40.8094 10.1804Z"
        fill="#34B44A"
      />
    </svg>
  );
}

export default SpoonFolkSvg;
