import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FooterLogo, logo_dark } from "../Assets/images/imageImport";
import {
  FacebookSvg,
  InstagramSvg,
  TwitterSvg,
  LinkedInSvg,
} from "../Assets/SvgIcons";
import axios from "axios";

function Footer({ marginTop }) {
  const containerStyle = `bg-[#333333] ${marginTop} md:relative z-30 md:flex md:flex-col md:py-12 px-6 lg:px-12 md:justify-around`;
  const listItemStyle = `font-inter text-white font-semibold text-base mb-3`;
  const [emailInput, setEmailInput] = useState(null);

  const BASE_URL = process.env.REACT_APP_RESERVATION_URL;

  // Check if the email is validate
  // const validateEmail = (email) => {
  //   const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  //   return re.test(email);
  // };

  // const Subscribe = async () => {
  //   if (emailInput) {
  //     if (validateEmail(emailInput)) {
  //       try {
  //         const response = await axios.get(
  //           `${BASE_URL}/email/subscribe?email=${emailInput}`
  //         );
  //         console.log("Here");
  //         if (response.status === 200) {
  //           alert("You have subscribed");
  //           window.location.reload();
  //         }
  //       } catch (error) {
  //         console.log("here");
  //         throw error;
  //       }
  //     } else {
  //       alert("Enter the correct email");
  //     }
  //   } else {
  //     alert("Enter your email first");
  //   }
  // };

  return (
    <div className={containerStyle}>
      <div className="flex flex-col md:flex-row md:mx-0 md:gap-10 lg:gap-20 gap-10 py-5">
        <div className="flex flex-col md:items-start md:ms-[3rem] md:mt-[0.5rem] mt-[2.5rem] md:mr-16">
          <div>
            <img className="" src={logo_dark} alt="FooterLogo" />
          </div>
          <div className="mt-8 text-base text-white">
            Empower your Brand Journey with Us
          </div>
        </div>
        <div className="flex flex-col grow">
          <div className="text-footerLabelText font-semibold text-sm">
            Product
          </div>
          <div className="pt-4">
            <ul>
              <li className={listItemStyle}>
                <Link to="/ev-users">EV Users</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="/charging-stations">Charging Stations</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="#">EV Manufacturers</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="/fleet-management">Fleet Management</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="/white-labels">White Label</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col grow">
          <div className="text-footerLabelText font-semibold text-sm">
            Company
          </div>
          <div className="pt-4">
            <ul>
              <li className={listItemStyle}>
                <Link to="#">About Us</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="#">Careers</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="/lets-talk">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col grow">
          <div className="text-footerLabelText font-semibold text-sm">
            Resources
          </div>
          <div className="pt-4">
            <ul>
              <li className={listItemStyle}>
                <Link to="#">Blog</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="#">Newsletter</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="#">Help Centre</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="#">Support</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col grow">
          <div className="text-footerLabelText font-semibold text-sm">
            Legal
          </div>
          <div className="pt-4">
            <ul>
              <li className={listItemStyle}>
                <Link to="/terms-of-use">Terms</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="/privacy-policy">Privacy</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="/payment-policy">Payment Gateway</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="/pricing-policy">Pricing</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="/refund-policy">Refund</Link>
              </li>
              <li className={listItemStyle}>
                <Link to="/lets-talk">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row pb-10 md:pb-0 gap-10 md:gap-0 md:justify-between text-white items-start md:ms-[3rem] md:mt-[0.5rem] mt-[2.5rem] md:pr-14">
        <div className="font-inter text-base order-1 md:order-0">
          © 2024 Vertecharge. All rights reserved.
        </div>
        <div className="flex md:flex-row gap-6 items-center order-0 md:order-1">
          <Link to="#" target="blank">
            <TwitterSvg />
          </Link>
          <Link
            to="https://www.linkedin.com/company/rhodiumtech/"
            target="blank"
          >
            <LinkedInSvg />
          </Link>
          <Link
            to="https://www.facebook.com/rhodiumtech?mibextid=9R9pXO"
            target="blank"
          >
            <FacebookSvg />
          </Link>
          <Link
            to="https://www.instagram.com/rhodiumtech/?igshid=NGVhN2U2NjQ0Yg%3D%3D"
            target="blank"
          >
            <InstagramSvg />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
