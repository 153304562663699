import React, { useState, useEffect } from "react";
import { LeftArrowSvg } from "../Features/Assets/SvgIcons";

function Form({
  ShowForm,
  HFBackButton,
  BQFBackButton,
  BQF2BackButton,
  formtype,
  DetailsFormData,
  PostRequest,
}) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => {
      const newData = { ...prevData };
      newData[fieldName] = value;
      return newData;
    });
  };

  let BackButton;
  if (formtype === "HomeQuestionForm") {
    BackButton = HFBackButton;
  } else if (formtype === "BusinessQuestionForm") {
    BackButton = BQFBackButton;
  } else if (formtype === "BusinessQuestionForm2") {
    BackButton = BQF2BackButton;
  }

  useEffect(() => {
      DetailsFormData({
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      });

  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const areFieldsEmpty = () => {
    return Object.values(formData).some((value) => value === "");
  };


  return (
    <>
      {ShowForm && (
        <form onSubmit={handleSubmit}>
          <div className="my-[3rem]">
            <div className="flex justify-center">
              <div className="lg:w-[55%] grid md:grid-cols-3 grid-cols-2 gap-8 mediaControlGrid mediaControlWidth">
                <div className=" flex flex-col gap-2 ">
                  <p className="font-bold text-xl text-headingColor">
                    1. Charging Type
                  </p>
                  <div className="bg-primaryColor h-1 rounded"></div>
                </div>
                <div className=" flex flex-col gap-2 ">
                  <p className="font-bold text-xl text-headingColor">
                    2. Recommendation
                  </p>
                  <div className="bg-primaryColor h-1 rounded"></div>
                </div>
                <div className=" flex flex-col gap-2 ">
                  <p className="font-bold text-xl text-headingColor">
                    3. Your details
                  </p>
                  <div className="bg-progressColor h-1 rounded"></div>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-[2.5rem]">
              <p className="font-normal sm:text-2xl text-xl text-headingColor">
                Your Details
              </p>
            </div>

            <div className=" w-[80%] mx-auto flex justify-center mt-[1rem] mb-[3rem]">
              <p className="font-medium sm:text-4xl text-3xl text-headingColor">
                Enter your details below
              </p>
            </div>

            <div className="lg:w-[70%] w-[80%] mx-auto">
              <h1 className="text-[18px] font-normal">
                Fill out the form below or{" "}
                <span className="text-primaryColor">send an email</span>
              </h1>

              <div className="grid md:grid-cols-2 grid-cols-1 mt-5 gap-10">
                <div className=" flex flex-col gap-5">
                  <input
                    type="text"
                    placeholder="Your Name"
                    className="border-progressColor outline-none border rounded py-4 ps-8"
                    value={formData.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="border border-progressColor outline-none rounded py-4 ps-8"
                    value={formData.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="Your Phone Number"
                    className="border border-progressColor outline-none rounded py-4 ps-8"
                    value={formData.phone}
                    onChange={(e) => handleInputChange("phone", e.target.value)}
                  />
                </div>

                <div className="flex md:mt-0 mt-[-0.98rem]">
                  <textarea
                    placeholder="Message"
                    className="md:h-full h-[10rem] w-full pt-4 ps-8 border rounded border-progressColor outline-none resize-none"
                    value={formData.message}
                    onChange={(e) =>
                      handleInputChange("message", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center w-[73%] mx-auto mt-[7rem] pb-[1rem] media-control-arrowbtn-div">
              <div
                onClick={BackButton}
                className="flex items-center gap-4 cursor-pointer"
              >
                <LeftArrowSvg />
                <p className="text-xl font-semibold">Back</p>
              </div>
              <button
              onClick={PostRequest}
              className={`bg-primaryColor font-semibold text-white px-[2.38rem] py-2 rounded ${areFieldsEmpty() ? 'cursor-not-allowed' : ''}`}
              disabled={areFieldsEmpty()}
              >
                SUBMIT
              </button>
            </div>

            <div className="flex-col items-center gap-8 hidden mt-[3rem] media-control-btn">
              <button
              onClick={PostRequest}
              className={`w-[80%] py-4 bg-primaryColor text-[20px] rounded text-white font-semibold  ${areFieldsEmpty() ? 'cursor-not-allowed' : ''}`}
              disabled={areFieldsEmpty()}
              >
                SUBMIT
              </button>
              <button
                onClick={BackButton}
                className="w-[80%] py-4 bg-progressColor text-[20px] rounded text-white font-semibold"
              >
                Back
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default Form;
