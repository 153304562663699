import React from "react";
import Lottie from "react-lottie-player";
import animation from "../Assets/animation/loadingAnimation.json";

function Loader({ showLoader }) {
  return (
    <>
      {showLoader && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/* Loader */}
              <Lottie
                loop
                animationData={animation}
                play
                style={{ width: 200, height: 200 }}
              />
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}

export default Loader;
