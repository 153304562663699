import React from "react";
import { PrivacyPolicyImage } from "../Features/Assets/images/imageImport";
import { SEO } from "../Features/UI";

const TermsOfUse = () => {
  return (
    <div class="relative">
      <SEO
        title="VerteCharge: Terms of Use"
        description="This policy outlines our terms of usage."
        name="VerteCharge"
        type="article"
        image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
        canonicalUrl={"https://vertecharge.com/terms-of-use"}
      />
      <div className="relative">
        <img
          class="w-full h-48 sm:h-80"
          src={PrivacyPolicyImage}
          alt="Terms of Use"
        />
        <div class="absolute text-3xl sm:text-5xl font-extrabold text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
          Terms of Use
        </div>
      </div>
      <div className={"w-5/6 mx-auto my-10 text-justify"}>
        <div className="font-bold my-5">Last Updated: 09/07/2024 </div>
        <div className="mt-2">
          These terms of use (including all other terms, policies, notices,
          guidelines, and forms referenced herein or otherwise available on the
          Platform or by hyperlink) (“Terms'') govern your use of the websites
          and mobile applications provided by VerteCharge. The "Services' ' we
          provide or make available include (a) the Platform, and (b) the
          services provided by the Platform and/or by VerteCharge and made
          available through the Platform. Any new features added to the Services
          are also subject to these Terms. These Terms govern your use of
          Services provided by VrteCharge.{" "}
        </div>
        <div className="mt-2">
          Please read these Terms carefully. By accessing and using the
          Platform, you agree that you have read, understood and accepted the
          Terms and consent to the processing of your personal data in
          accordance with the terms set out in the{" "}
          <a
            href="https://www.vertecharge.com/privacy-policy"
            className="text-green-500 font-semibold"
          >
            Privacy Policy
          </a>
          . If you do not agree with the Terms, please do not access or use the
          Platform, or the Services.{" "}
        </div>
        <div className="mt-2">
          The Platform may be used by (i) natural persons who have reached 18
          years of age and (ii) corporate legal entities, e.g companies. Where
          applicable, these Terms shall be subject to country-specific
          provisions as set out herein.
        </div>
        <div className="mt-2">
          Users below the age of 18 must obtain consent from parent(s) or legal
          guardian(s), who by accepting these Terms shall agree to take
          responsibility for your actions and any charges associated with your
          use of the Platform and/or purchase of Goods (as defined below). If
          you do not have consent from your parent(s) or legal guardian(s), you
          must stop using/accessing the Platform immediately.{" "}
        </div>
        <div className="mt-2">
          You agree and acknowledge that, as part of VerteCharge’s policy and/or
          whenever it is deemed necessary, VerteCharge and/or any third party
          engaged by VerteCharge, may conduct Background Checks on you on an
          ongoing basis, without further reference or notice to you. “Background
          Checks” includes, without limitation, government or financial
          sanctions, civil or criminal litigation activities, adverse news. If
          VerteCharge, in its sole discretion, is not satisfied with the results
          of the Background Checks, VerteCharge may terminate your VerteCharge
          account and/or use of the Platform.{" "}
        </div>
        <div className="mt-2">
          VerteCharge reserves the right to change or modify these Terms
          (including any additional terms and conditions or policies, which are
          published on the Platform and incorporated into these Terms) at any
          time. It is your responsibility to review the Terms regularly and you
          are strongly recommended to do so. You will be deemed to have agreed
          to the amended Terms (whether or not reviewed by you) by your
          continued use of the Platform following the date on which the amended
          Terms are posted.{" "}
        </div>
        <div className="mt-2">
          All photos and images of the Goods shown on the Platform are also for
          illustrative purposes only and may differ from the actual Goods.{" "}
        </div>
        <div className="font-bold my-5">Definitions</div>
        <div className="mt-2">"Company" refers to Verte Charge. </div>
        <div className="mt-2">
          "User" refers to any individual or entity accessing or using the
          website.
        </div>
        <div className="mt-2">
          "Services" refers to the EV infrastructure integration solutions
          provided by Verte Charge.
        </div>
        <div className="font-bold my-5">Use of the Website.</div>
        <div className="mt-2">
          Eligibility: You must be at least 18 years old to use our website.{" "}
        </div>
        <div className="mt-2">
          Account Security: You are responsible for maintaining the
          confidentiality of your account information and for all activities
          that occur under your account.{" "}
        </div>
        <div className="mt-2">
          Prohibited Activities: You agree not to use the website for any
          unlawful purpose or any purpose prohibited by these Terms.{" "}
        </div>
        <div className="font-bold my-5">Use of the VerteCharge Account.</div>
        <div className="mt-2">
          You will need to register for a VerteChagre account for you to use the
          Platform. When you register for a VerteChagre account we will ask you
          to provide your personal information including a valid email address,
          a mobile phone number and a unique password. To purchase an Order,
          depending on which payment method you opt for, you may need to provide
          us with your credit card details. Your unique password should not be
          shared with anyone and you agree to keep it secret at all times. You
          are solely responsible for keeping your password safe. Save for cases
          of fraud or abuse which are not your fault, you accept that all Orders
          placed under your VerteChagre account are your sole responsibility.{" "}
        </div>
        <div className="mt-2">
          VerteChagre shall not be liable for Orders that encounter delivery
          issues due to incomplete, incorrect or missing information provided by
          you. You are obliged to provide information that is complete, accurate
          and truthful for the proper processing of the Order, including your
          delivery address and contact information.{" "}
        </div>
        <div className="mt-2">
          If you wish to delete your VerteChagre account, please send us an
          email requesting the same. We may restrict, suspend or terminate your
          VrteCharge account and/or use of the Platform, if we reasonably
          believe that:{" "}
          <div className="mt-2 mx-8">
            - someone other than you is using your VrteCharge account;
          </div>
          <div className="mt-2 mx-8">
            - where you are suspected or discovered to have been involved in any
            activity or conduct that is in breach of these Terms, our policies
            and guidelines, or involved in activity or conduct which we deem in
            our sole discretion to be an abuse of the Platform;{" "}
          </div>
          <div className="mt-2 mx-8">
            - you are unable to satisfy the results of the Background Checks on
            an ongoing basis.
          </div>
        </div>
        <div className="font-bold my-5">Restrictions</div>
        <div className="mt-2 font-semibold">
          - Activities Prohibited on the Platform
        </div>
        <div className="mt-2">
          The following is a non-exhaustive list of the types of conduct that
          are illegal or prohibited on the Platform. VrteCharge reserves the
          right to investigate and take appropriate legal action against anyone
          who, in VrteCharge's sole discretion, engages in any of the prohibited
          activities. Prohibited activities include, but are not limited to the
          following:
          <div className="mt-2 mx-8">
            - using the Platform for any purpose in violation of local, state,
            or federal laws or regulations, including any false, deceptive,
            misleading, or fraudulent purpose;
          </div>
          <div className="mt-2 mx-8">
            - posting any content that infringes the intellectual property
            rights, privacy rights, publicity rights, trade secret rights, or
            any other rights of any party;
          </div>
          <div className="mt-2 mx-8">
            - posting content that is unlawful, obscene, defamatory,
            threatening, harassing, abusive, slanderous, hateful, or
            embarrassing to any other person or entity as determined by
            VrteCharge in its sole discretion or pursuant to local community
            standards;
          </div>
          <div className="mt-2 mx-8">
            - posting content that constitutes cyber-bullying, as determined by
            VrteCharge in its sole discretion;
          </div>
          <div className="mt-2 mx-8">
            - posting content that depicts any dangerous, life-threatening, or
            otherwise risky behavior;
          </div>
          <div className="mt-2 mx-8">
            - posting telephone numbers, street addresses, or last names of any
            person;
          </div>
          <div className="mt-2 mx-8">
            - posting URLs to external websites or any form of HTML or
            programming code;
          </div>
          <div className="mt-2 mx-8">
            - posting anything that may be “spam,” as determined by VrteCharge
            in its sole discretion;
          </div>
          <div className="mt-2 mx-8">
            - impersonating another person when posting content;
          </div>
          <div className="mt-2 mx-8">
            - harvesting or otherwise collecting information about others,
            including email addresses, without their consent;
          </div>
          <div className="mt-2 mx-8">
            - allowing any other person or entity to use your identification for
            posting or viewing comments;
          </div>
          <div className="mt-2 mx-8">
            - harassing, threatening, stalking, or abusing any person on the
            Platform;
          </div>
          <div className="mt-2 mx-8">
            - engaging in any other conduct that restricts or inhibits any other
            person from using or enjoying the Platform, or which, in the sole
            discretion of VrteCharge, exposes VrteCharge or any of its
            customers, suppliers, or any other parties to any liability or
            detriment of any type;
          </div>
          <div className="mt-2 mx-8">
            - encouraging other people to engage in any prohibited activities as
            described herein.
          </div>
        </div>
        <div className="mt-2">
          VrteCharge reserves the right but is not obligated to do any or all of
          the following:
          <div className="mt-2 mx-8">
            - investigate an allegation that any content posted on the Platform
            does not conform to these Terms and determine in its sole discretion
            to remove or request the removal of the content;
          </div>
          <div className="mt-2 mx-8">
            - remove content which is abusive, illegal, or disruptive, or that
            otherwise fails to conform with these Terms;
          </div>
          <div className="mt-2 mx-8">
            - suspend or terminate a user’s access to the Platform or their
            VrteCharge account upon any breach of these Terms;
          </div>
          <div className="mt-2 mx-8">
            - monitor, edit, or disclose any content on the Platform;
          </div>
          <div className="mt-2 mx-8">
            - edit or delete any content posted on the Platform, regardless of
            whether such content violates these standards.
          </div>
        </div>
        <div className="font-bold my-5">Services Provided</div>
        <div className="mt-2">
          Scope: Verte Charge provides solutions for integrating EV
          infrastructure into existing platforms, particularly targeting oil
          marketing companies and other large enterprises.
        </div>
        <div className="mt-2">
          Service Availability: We strive to ensure that our services are
          available.
        </div>
        <div className="mt-2">
          However, we do not guarantee uninterrupted access and are not liable
          for any downtime.
        </div>
        <div className="font-bold my-5">User Obligations</div>
        <div className="mt-2">
          Compliance: Users must comply with all applicable laws and regulations
          when using our services.{" "}
        </div>
        <div className="mt-2">
          Accurate Information: Users must provide accurate and complete
          information when creating an account or using our services.{" "}
        </div>
        <div className="font-bold my-5">Intellectual Property</div>
        <div>
          All trademarks, logos, images, and service marks, including these
          Terms as displayed on the Platform or in our marketing material,
          whether registered or unregistered, are the intellectual property of
          VerteCharge and/or third parties who have authorized us with the use
          (collectively the “Trademarks”). You may not use, copy, reproduce,
          republish, upload, post, transmit, distribute, or modify these
          Trademarks in any way without our prior express written consent. The
          use of the Trademarks on any other website not approved by us is
          strictly prohibited. VerteCharge will aggressively enforce its
          intellectual property rights to the fullest extent of the law,
          including criminal prosecution. VerteCharge neither warrants nor
          represents that your use of materials displayed on the Platform will
          not infringe rights of third parties not owned by or affiliated with
          VerteCharge. Use of any materials on the Platform is at your own risk
        </div>
        <div className="font-bold my-5">Privacy</div>
        <div className="mt-2">
          Data Collection: We collect and use personal data in accordance with
          our Privacy Policy.
        </div>
        <div className="mt-2">
          Consent: By using our website, you consent to the collection and use
          of your data as described in the Privacy Policy.{" "}
        </div>
        <div className="font-bold my-5">Limitation of Liability</div>
        <div className="mt-2">
          No Warranties: The website and services are provided "as is" without
          any warranties, express or implied.
        </div>
        <div className="mt-2">
          Limitation: Verte Charge is not liable for any indirect, incidental,
          or consequential damages arising out of your use of the website or
          services.
        </div>
        <div className="font-bold my-5">Indemnification</div>
        <div className="mt-2">
          You agree to indemnify and hold Verte Charge harmless from any claims,
          damages, or expenses arising out of your use of the website or
          services or your violation of these Terms.{" "}
        </div>
        <div className="font-bold my-5">Changes to Terms</div>
        <div className="mt-2">
          We reserve the right to modify these Terms at any time. Any changes
          will be effective immediately upon posting on the website. Your
          continued use of the website constitutes your acceptance of the
          revised Terms.
        </div>
        <div className="font-bold my-5">Severability</div>
        <div className="mt-2">
          If any provision of these Terms is found to be invalid by any court
          having competent jurisdiction, the invalidity of such provision shall
          not affect the validity of the remaining provisions of these Terms,
          which shall remain in full force and effect. No waiver of any
          provision in these Terms shall be deemed a further or continuing
          waiver of such provision or any other provision.{" "}
        </div>
        <div className="font-bold my-5">Governing Law</div>
        <div className="mt-2">
          These Terms are governed by and construed in accordance with the laws
          of the Islamic Republic of Pakistan, without regard to its conflict of
          law principles.{" "}
        </div>

        <div className="font-bold my-5">Contact Us</div>
        <div>
          For any questions or further clarifications, please feel free to reach
          out at our office at 17-Lawrence Road, Jinnah Town, Lahore.
          <div>
            Or contact us at our customer support team at{" "}
            <a
              href={`mailto:support@rhodium-tech.com`}
              className={"text-green-500 font-bold"}
            >
              support@rhodium-tech.com
            </a>{" "}
            or call us at{" "}
            <a
              href={`phone:+923357463486`}
              className={"text-green-500 font-bold"}
            >
              (+92) 335 746 3486
            </a>
          </div>
          <div className="mt-2">
            By using our website and services, you acknowledge that you have
            read, understood, and agree to be bound by these Terms and
            Conditions
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
