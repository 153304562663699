import React from "react";
import { Card } from "../Features/UI";
import "../Custom-CSS/Lets-Talk.css";

function SelectChargingPlan({ ShowCard, onClick1, onClick2, onClick3 }) {
  return (
    <>
      {ShowCard && (
        <div className="my-[3rem]">
          <div className="flex justify-center">
            <div className="lg:w-[55%]  grid md:grid-cols-3 grid-cols-2 gap-8 mediaControlGrid mediaControlWidth">
              <div className=" flex flex-col gap-2 ">
                <p className="font-bold text-xl text-headingColor">
                  1. Charging Type
                </p>
                <div className="bg-progressColor h-1 rounded"></div>
              </div>
              <div className=" flex flex-col gap-2 ">
                <p className="font-bold text-xl text-headingColor">
                  2. Recommendation
                </p>
                <div className="bg-progressColor h-1 rounded"></div>
              </div>
              <div className=" flex flex-col gap-2 ">
                <p className="font-bold text-xl text-headingColor">
                  3. Your details
                </p>
                <div className="bg-progressColor h-1 rounded"></div>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-[2.5rem]">
            <p className="font-normal sm:text-2xl text-xl text-headingColor">
              Lets Get Started
            </p>
          </div>

          <div className="w-[80%] mx-auto flex justify-center mt-[1rem]">
            <p className="font-medium sm:text-4xl text-center text-3xl text-headingColor">
              Select the type of charging <br className="display"  /> solution you’re looking for
            </p>
          </div>

          {/* Card Section */}
          <Card
            Title1Text={"Home Charging"}
            Title2Text={"Business Charging"}
            Title3Text={"DC Fast Charging"}
            Para1Text={
              "Make your Home charging experience smart and secure with our AC home charger"
            }
            Para2Text={
              "Make your Business charging experience smart and secure with our AC home charger"
            }
            Para3Text={
              "Make your DC charging experience smart and secure with our fast DC charger"
            }
            btnText1={"For EV Drivers"}
            btnText2={"For Business"}
            btnText3={"For Business"}
            onClick1={onClick1}
            onClick2={onClick2}
            onClick3={onClick3}
          />
        </div>
      )}
    </>
  );
}

export default SelectChargingPlan;
