import React from "react";
import { PrivacyPolicyImage } from "../Features/Assets/images/imageImport";
import { SEO } from "../Features/UI";

const PaymentGatewayPolicy = () => {
  return (
    <div class="relative">
      <SEO
        title="VerteCharge: Payment Gateway Policy"
        description="This Payment Gateway Policy outlines the terms and conditions for the use of the payment gateway provided by our Payment Partners for transactions related to Verte Charge's products and services. Verte Charge offers a range of products related to EV infrastructure, including EV Chargers, Charging Station Management Software, and Installation and Maintenance Services."
        name="VerteCharge"
        type="article"
        image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
        canonicalUrl={"https://vertecharge.com/payment-policy"}
      />
      <div className="relative">
        <img
          class="w-full h-48 sm:h-80"
          src={PrivacyPolicyImage}
          alt="Payment Policy"
        />
        <div class="absolute text-3xl sm:text-5xl font-extrabold text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
          Payment Policy
        </div>
      </div>
      <div className={"w-5/6 mx-auto my-10 text-justify"}>
        <div className="font-bold my-5">Last Updated: 09/07/2024 </div>
        <div>
          This Payment Gateway Policy outlines the terms and conditions for the
          use of the payment gateway provided by our Payment Partners for
          transactions related to Verte Charge's products and services. Verte
          Charge offers a range of products related to EV infrastructure,
          including EV Chargers, Charging Station Management Software, and
          Installation and Maintenance Services.
        </div>
        <div className="font-bold my-5">Payment Gateway Services</div>
        <div>
          Provider:The payment gateway services are provided by our Payment
          Partners.
        </div>
        <div className="mt-2">
          Purpose: The payment gateway facilitates secure online payments for
          Verte Charge's products and services.
        </div>
        <div className="font-bold my-5">Payment Methods</div>
        <div>
          Accepted Methods: The payment gateway accepts payments via credit
          cards, debit cards, online wallets and bank transfers.
        </div>
        <div className="mt-2">
          Currency: All transactions will be processed in PKR.
        </div>
        <div className="font-bold my-5">Subscription Billing</div>
        <div className="mt-2">
          Recurring Billing: Subscriptions to Verte Charge's products are billed
          on a recurring basis as per the selected subscription plan.
        </div>
        <div className="mt-2">
          Billing Cycle: Billing cycles are specified in each customer's
          subscription agreement and can be monthly, quarterly, or annually.
        </div>
        <div className="mt-2">
          Automatic Renewal: Subscriptions renew automatically unless canceled
          by the customer.
        </div>

        <div className="font-bold my-5">Transaction Fees:</div>
        <div className="mt-2">
          Our Payment Partner charges a transaction fee of 2.5% per transaction
          for all payments processed through its payment gateway.
        </div>
        <div className="mt-2">
          This fee applies to both local and international transactions
        </div>
        <div className="font-semibold my-5">- Currency Conversion Fees</div>
        <div className="mt-2">
          For transactions involving currency conversion, an additional fee of
          2% above the prevailing exchange rate will be applied.
        </div>
        <div className="font-bold my-5">Premium Subscription Members:</div>
        <div className="font-semibold my-5">- Definition</div>
        <div className="mt-2">
          Premium Subscription Members are users who have subscribed to premium
          services or products offered by our platform.
        </div>
        <div className="mt-2">
          They are entitled to exclusive benefits, including discounted
          transaction fees for payments made through our Payment Partners.
        </div>
        <div className="font-semibold my-5">- Discounted Transaction Fees</div>
        <div className="mt-2">
          Premium Subscription Members enjoy a discounted transaction fee of 2%
          per transaction when using Payment Partner's payment gateway.
        </div>
        <div className="mt-2">
          This discounted rate applies to both domestic and international
          transactions.
        </div>
        <div className="font-bold my-5">Security and Compliance:</div>
        <div className="mt-2">
          Encryption: All payment transactions are encrypted using SSL
          technology to protect sensitive information.
        </div>
        <div className="mt-2">
          PCI Compliance: The payment gateway complies with Payment Card
          Industry Data Security Standard (PCI DSS) requirements to ensure
          secure handling of credit card information.
        </div>
        <div className="font-bold my-5">Transaction Processing:</div>
        <div className="mt-2">
          Authorization: Transactions are subject to authorization by the
          issuing bank and our Payment Partners.
        </div>
        <div className="mt-2">
          Confirmation: Users receive a confirmation email upon successful
          completion of a transaction.
        </div>
        <div className="mt-2">
          Failed Transactions: If a transaction fails, users are notified and
          guided to retry or use an alternative payment method.{" "}
        </div>
        <div className="font-bold my-5">Billing Disputes:</div>
        <div className="mt-2">
          Dispute Resolution: Customers must report any billing disputes to
          Verte Charge's customer support team within 7 days of the transaction
          date. ● Investigation: Verte Charge, in collaboration with our Payment
          Partners. , will investigate billing disputes and provide resolution
          within a reasonable timeframe.
        </div>
        <div className="font-bold my-5">Fees and Refunds:</div>
        <div className="mt-2">
          Transaction Fees: Any transaction fees charged by Payment Partners
          will be borne by Verte Charge.{" "}
        </div>
        <div className="mt-2">
          Refunds: Refunds for subscriptions or software licenses are subject to
          Verte Charge's Refund Policy and processed back to the original
          payment method used for the transaction.{" "}
        </div>
        <div className="font-bold my-5">Changes to the Policy:</div>
        <div className="mt-2">
          Notification: Changes to this Payment Gateway Policy will be
          communicated to customers via email and updated on Verte Charge's
          website.{" "}
        </div>
        <div className="mt-2">
          Effective Date: Changes will take effect from the date specified in
          the notification.{" "}
        </div>
        <div className="font-bold my-5">Contact Us</div>
        <div>
          For any questions or further clarifications, please feel free to reach
          out at our office at 17-Lawrence Road, Jinnah Town, Lahore.
          <div>
            Or contact us at our customer support team at{" "}
            <a
              href={`mailto:support@rhodium-tech.com`}
              className={"text-green-500 font-bold"}
            >
              support@rhodium-tech.com
            </a>{" "}
            or call us at{" "}
            <a
              href={`phone:+923357463486`}
              className={"text-green-500 font-bold"}
            >
              (+92) 335 746 3486
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentGatewayPolicy;
