import React from "react";

function StarSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        d="M6.7871 1.36772C7.22428 -0.0674593 9.25612 -0.0674617 9.69329 1.36772L10.3149 3.40828C10.5096 4.04771 11.0995 4.48467 11.768 4.48467H13.9777C15.4254 4.48467 16.0525 6.31755 14.9082 7.20434L12.9554 8.71776C12.457 9.10398 12.249 9.75791 12.4328 10.3611L13.1383 12.6774C13.57 14.0943 11.9255 15.228 10.7548 14.3207L9.17068 13.093C8.62297 12.6686 7.85742 12.6686 7.30971 13.093L5.72563 14.3207C4.55485 15.228 2.91043 14.0943 3.34205 12.6774L4.04762 10.3611C4.23135 9.75791 4.02338 9.10398 3.52502 8.71776L1.57217 7.20434C0.427897 6.31756 1.05499 4.48467 2.50266 4.48467H4.71243C5.38086 4.48467 5.97075 4.04771 6.16552 3.40828L6.7871 1.36772Z"
        fill="#FF9A23"
      />
    </svg>
  );
}

export default StarSvg;
