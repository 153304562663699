import React from "react";

function LinkedInSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="25"
      viewBox="0 0 40 37"
      fill="none"
    >
      <path
        d="M9.0833 4.83139C9.08277 5.90398 8.65587 6.93243 7.89653 7.69049C7.13718 8.44855 6.10759 8.87412 5.03426 8.87358C3.96092 8.87304 2.93176 8.44644 2.17317 7.68763C1.41459 6.92881 0.988722 5.89994 0.989258 4.82735C0.989795 3.75476 1.41669 2.72631 2.17603 1.96825C2.93538 1.21019 3.96497 0.78462 5.0383 0.785157C6.11164 0.785693 7.1408 1.21229 7.89939 1.97111C8.65797 2.72992 9.08384 3.7588 9.0833 4.83139ZM9.20471 11.8683H1.11067V37.1851H9.20471V11.8683ZM21.9933 11.8683H13.9397V37.1851H21.9124V23.8999C21.9124 16.4989 31.5645 15.8114 31.5645 23.8999V37.1851H39.5574V21.1498C39.5574 8.67339 25.2714 9.13848 21.9124 15.2655L21.9933 11.8683Z"
        fill="white"
      />
    </svg>
  );
}

export default LinkedInSvg;
