import React, { useState } from "react";
import logo from "../Assets/images/logo.png";
import { Menu } from "../Assets/SvgIcons";
import CloseSvg from "../Assets/SvgIcons/CloseSvg";
import { Link, useNavigate, useLocation } from "react-router-dom";

const navlinks = [
  { name: "EV Users", link: "/ev-users" },
  { name: "Station Owners", link: "/station-owners" },
  { name: "Home Charging", link: "/home-charging" },
  { name: "Charging Stations", link: "/charging-stations" },
  { name: "Fleet Management", link: "/fleet-management" },
  { name: "White Label", link: "/white-labels" },
];

function CustomNavbar() {
  // State
  const [isOpen, setIsOpen] = useState(false);

  // Location
  const location = useLocation();

  // Function to open the navBar on small screens
  const HandleOnClick = () => {
    setIsOpen(!isOpen);
  };

  const getNavLinkClass = (path) => {
    return location.pathname === path
      ? "text-primaryColor border-b-primaryColor"
      : "";
  };

  const styles = {
    "@media (min-width: 0px) and (max-width: 640px)": {
      navList: {
        top: isOpen ? "60px" : "-480px",
      },
    },
  };

  const navigate = useNavigate();

  const handleOnPress = () => {
    navigate("/lets-talk");
    setIsOpen(false);
  };

  return (
    <div>
      <nav className="sm:w-[90%] mx-auto flex items-center justify-between respond  py-3 font-bold z-30">
        <div className="logo sm:block flex justify-between items-center sm:ms-0 ms-5 ">
          <Link to={"/"}>
            <img className="w-[8rem]" src={logo} alt="Logo" />
          </Link>

          <div>
            <div className="sm:hidden mr-5 sm:mr-0" onClick={HandleOnClick}>
              {isOpen ? <CloseSvg fill="black" /> : <Menu />}
            </div>
          </div>
        </div>
        <ul
          className="flex items-center gap-5 list"
          style={
            styles["@media (min-width: 0px) and (max-width: 640px)"].navList
          }
        >
          {navlinks.map((item, index) => {
            return (
              <div>
                <li
                  className={`list-items border-b-2 inline-block pb-1 border-[#ffffff] ${getNavLinkClass(
                    item.link
                  )}`}
                  key={index}
                >
                  {/* <a href={item.link}>{item.name}</a> */}
                  <Link to={item.link}>{item.name}</Link>
                </li>
              </div>
            );
          })}
          <button
            onClick={handleOnPress}
            className="bg-primaryColor sm:hidden  py-2 px-4 rounded text-white sm:mb-0 sm:mt-0 mt-[15px] mb-[15px]"
          >
            Lets Talk?
          </button>
        </ul>
        <div className="button sm:block hidden">
          <button
            onClick={handleOnPress}
            className="bg-primaryColor  py-2 px-4 rounded text-white sm:mb-0 sm:mt-0 mt-[15px] mb-[15px]"
          >
            Lets Talk?
          </button>
        </div>
      </nav>
    </div>
  );
}

export default CustomNavbar;
