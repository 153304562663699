import React from "react";

function InstagramSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="27"
      viewBox="0 0 40 39"
      fill="none"
    >
      <path
        d="M11.394 0H27.774C34.014 0 39.084 5.07 39.084 11.31V27.69C39.084 30.6896 37.8924 33.5663 35.7714 35.6874C33.6503 37.8084 30.7736 39 27.774 39H11.394C5.15398 39 0.0839844 33.93 0.0839844 27.69V11.31C0.0839844 8.3104 1.27557 5.43366 3.39661 3.31262C5.51764 1.19159 8.39439 0 11.394 0ZM11.004 3.9C9.14216 3.9 7.3566 4.63961 6.0401 5.95611C4.72359 7.27262 3.98398 9.05818 3.98398 10.92V28.08C3.98398 31.9605 7.12348 35.1 11.004 35.1H28.164C30.0258 35.1 31.8114 34.3604 33.1279 33.0439C34.4444 31.7274 35.184 29.9418 35.184 28.08V10.92C35.184 7.0395 32.0445 3.9 28.164 3.9H11.004ZM29.8215 6.825C30.468 6.825 31.0879 7.08181 31.5451 7.53893C32.0022 7.99605 32.259 8.61604 32.259 9.2625C32.259 9.90897 32.0022 10.529 31.5451 10.9861C31.0879 11.4432 30.468 11.7 29.8215 11.7C29.175 11.7 28.555 11.4432 28.0979 10.9861C27.6408 10.529 27.384 9.90897 27.384 9.2625C27.384 8.61604 27.6408 7.99605 28.0979 7.53893C28.555 7.08181 29.175 6.825 29.8215 6.825ZM19.584 9.75C22.1698 9.75 24.6498 10.7772 26.4783 12.6057C28.3068 14.4342 29.334 16.9141 29.334 19.5C29.334 22.0859 28.3068 24.5658 26.4783 26.3943C24.6498 28.2228 22.1698 29.25 19.584 29.25C16.9981 29.25 14.5182 28.2228 12.6897 26.3943C10.8612 24.5658 9.83398 22.0859 9.83398 19.5C9.83398 16.9141 10.8612 14.4342 12.6897 12.6057C14.5182 10.7772 16.9981 9.75 19.584 9.75ZM19.584 13.65C18.0325 13.65 16.5445 14.2663 15.4474 15.3634C14.3503 16.4605 13.734 17.9485 13.734 19.5C13.734 21.0515 14.3503 22.5395 15.4474 23.6366C16.5445 24.7337 18.0325 25.35 19.584 25.35C21.1355 25.35 22.6235 24.7337 23.7206 23.6366C24.8176 22.5395 25.434 21.0515 25.434 19.5C25.434 17.9485 24.8176 16.4605 23.7206 15.3634C22.6235 14.2663 21.1355 13.65 19.584 13.65Z"
        fill="white"
      />
    </svg>
  );
}

export default InstagramSvg;
