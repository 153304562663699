import React from "react";
import { Hooray } from "../Assets/images/imageImport";

function Modal({ showModal, onClickModal }) {

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-[1.5rem] shadow-lg relative flex flex-col w-full md:px-8 md:pt-10 px-8 pt-8 bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative flex md:flex-row flex-col items-center justify">
                  <div className="md:hidden block">
                    <img src={Hooray} alt="Hooray" />
                  </div>
                  <div>
                    <p className="text-center text-blueGray-500 font-bold md:text-3xl text-2xl">
                      Your Query is Submitted
                    </p>
                    <p className="text-center text-blueGray-500 font-semibold md:text-[22px] text-[18px] mt-[0.31rem]">
                      We will get back to you shortly
                    </p>
                  </div>
                  <div className="md:block hidden">
                    <img src={Hooray} alt="Hooray" />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex mt-8 pb-5 items-center justify-center">
                  <button
                    className="text-white rounded bg-primaryColor font-bold uppercase px-6 py-3 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onClickModal}
                  >
                    Explore Home
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default Modal;
