import React from "react";

function ChargerTypeSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="27"
      viewBox="0 0 45 37"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.6596 0.157471C37.809 0.157471 38.9214 0.628397 39.6255 1.53676C42.4849 5.22533 44.2 9.84445 44.2 14.8725C44.2 26.9295 34.4245 36.705 22.3676 36.705C10.3106 36.705 0.535156 26.9295 0.535156 14.8725C0.535156 9.84445 2.25029 5.22533 5.10974 1.53676C5.81383 0.628397 6.92631 0.157471 8.07562 0.157471H36.6596ZM30.5548 14.8725C30.5548 17.5088 32.6944 19.6484 35.3306 19.6484C37.9669 19.6484 40.1064 17.5088 40.1064 14.8725C40.1064 12.2362 37.9669 10.0967 35.3306 10.0967C32.6944 10.0967 30.5548 12.2362 30.5548 14.8725ZM28.8491 29.9342C26.2128 29.9342 24.0733 27.7947 24.0733 25.1584C24.0733 22.5193 26.2128 20.3825 28.8491 20.3825C31.4854 20.3825 33.625 22.5193 33.625 25.1584C33.625 27.7947 31.4854 29.9342 28.8491 29.9342ZM22.3676 10.0967C19.7314 10.0967 17.5917 12.2362 17.5917 14.8725C17.5917 17.5088 19.7314 19.6484 22.3676 19.6484C25.0039 19.6484 27.1435 17.5088 27.1435 14.8725C27.1435 12.2362 25.0039 10.0967 22.3676 10.0967ZM15.8861 29.9342C13.2498 29.9342 11.1103 27.7947 11.1103 25.1584C11.1103 22.5193 13.2498 20.3825 15.8861 20.3825C18.5224 20.3825 20.6619 22.5193 20.6619 25.1584C20.6619 27.7947 18.5224 29.9342 15.8861 29.9342ZM9.40456 10.0967C6.76829 10.0967 4.62874 12.2362 4.62874 14.8725C4.62874 17.5088 6.76829 19.6484 9.40456 19.6484C12.0408 19.6484 14.1805 17.5088 14.1805 14.8725C14.1805 12.2362 12.0408 10.0967 9.40456 10.0967ZM15.8861 3.98088C17.3925 3.98088 18.6151 5.20347 18.6151 6.70991C18.6151 8.21635 17.3925 9.43902 15.8861 9.43902C14.3797 9.43902 13.1571 8.21635 13.1571 6.70991C13.1571 5.20347 14.3797 3.98088 15.8861 3.98088ZM31.5782 6.70991C31.5782 5.20347 30.3555 3.98088 28.8491 3.98088C27.3427 3.98088 26.1201 5.20347 26.1201 6.70991C26.1201 8.21635 27.3427 9.43902 28.8491 9.43902C30.3555 9.43902 31.5782 8.21635 31.5782 6.70991Z"
        fill="#34B44A"
      />
    </svg>
  );
}

export default ChargerTypeSvg;
