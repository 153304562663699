import React, { useEffect, useState } from "react";
import { LeftArrowSvg } from "../Features/Assets/SvgIcons";
import "../Custom-CSS/Lets-Talk.css";

function HomeQuestion({
  ShowHomeQuestion,
  onClick,
  HomeQustionBackButton,
  HQFormData,
}) {
  const [yesColor1, setYesColor1] = useState("white");
  const [noColor1, setNoColor1] = useState("white");
  const [yesColor2, setYesColor2] = useState("white");
  const [noColor2, setNoColor2] = useState("white");

  const [formData, setFormData] = useState({
    make: "",
    model: "",
    professional_installation: "",
    reimbursement: "",
  });

  useEffect(() => {
    HQFormData({
      make: formData.make,
      model: formData.model,
      professional_installation: formData.professional_installation,
      reimbursement: formData.reimbursement,
    });
  }, [formData]);

  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => {
      const newData = { ...prevData };
      if (
        fieldName === "professional_installation" ||
        fieldName === "reimbursement"
      ) {
        newData[fieldName] = value === "Yes"; // Store boolean values
      } else {
        newData[fieldName] = value;
      }
      return newData;
    });
  };

  const handleYesClick1 = () => {
    setYesColor1("primaryColor");
    setNoColor1("white");
    handleInputChange("professional_installation", "Yes");
  };

  const handleNoClick1 = () => {
    setNoColor1("primaryColor");
    setYesColor1("white");
    handleInputChange("professional_installation", "No");
  };

  const handleYesClick2 = () => {
    setYesColor2("primaryColor");
    setNoColor2("white");
    handleInputChange("reimbursement", "Yes");
  };

  const handleNoClick2 = () => {
    setNoColor2("primaryColor");
    setYesColor2("white");
    handleInputChange("reimbursement", "No");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const areFieldsEmpty = () => {
    return Object.values(formData).some((value) => value === "");
  };

  return (
    <>
      {ShowHomeQuestion && (
        <div className="my-[3rem]">
          <div className="flex justify-center">
            <div className="lg:w-[55%]  grid md:grid-cols-3 grid-cols-2 gap-8 mediaControlGrid mediaControlWidth">
              <div className=" flex flex-col gap-2 ">
                <p className="font-bold text-xl text-headingColor">
                  1. Charging Type
                </p>
                <div className="bg-primaryColor h-1 rounded"></div>
              </div>
              <div className=" flex flex-col gap-2 ">
                <p className="font-bold text-xl text-headingColor">
                  2. Recommendation
                </p>
                <div className="bg-progressColor h-1 rounded"></div>
              </div>
              <div className=" flex flex-col gap-2 ">
                <p className="font-bold text-xl text-headingColor">
                  3. Your details
                </p>
                <div className="bg-progressColor h-1 rounded"></div>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-[2.5rem]">
            <p className="font-normal sm:text-2xl text-xl text-headingColor">
              Your Preferences
            </p>
          </div>

          <div className="w-[80%] mx-auto flex justify-center mt-[1rem] mb-[3rem]">
            <p className="font-medium sm:text-4xl text-center text-3xl text-headingColor">
              Tell us about us your home charging needs
            </p>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="w-[80%] mx-auto flex flex-col gap-8">
              <div className="grid md:grid-cols-2 grid-cols-1">
                <div className="text-headingColor text-xl font-normal flex">
                  Which electric vehicle do you drive?
                </div>
                <div className="flex gap-4 md:justify-end md:mt-0 mt-5">
                  <input
                    className="bg-progressColor outline-none ps-5 text-greyTextColor py-2 rounded w-[8.3rem]"
                    placeholder="e.g Tesla"
                    value={formData.make}
                    onChange={(e) => handleInputChange("make", e.target.value)}
                  />
                  <input
                    className="bg-progressColor outline-none ps-5 text-greyTextColor py-2 rounded w-[8.3rem]"
                    placeholder="e.g Model 3"
                    value={formData.model}
                    onChange={(e) => handleInputChange("model", e.target.value)}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 grid-cols-1">
                <div className="text-headingColor text-xl font-normal flex ">
                  Do you need professional installation?
                </div>
                <div className="flex gap-[6rem] md:justify-end md:mr-12 md:mt-0 mt-5">
                  <div className="flex gap-5 justify-center items-center">
                    <div
                      onClick={handleYesClick1}
                      className="h-5 w-5 border-[3px] flex justify-center items-center border-primaryColor rounded-full"
                    >
                      <div
                        className={`h-2 w-2 rounded-full bg-${yesColor1}`}
                      ></div>
                    </div>
                    <p className="text-xl font-semibold">Yes</p>
                  </div>

                  <div className="flex gap-5 justify-center items-center">
                    <div
                      onClick={handleNoClick1}
                      className="h-5 w-5 border-[3px] flex justify-center items-center border-primaryColor rounded-full"
                    >
                      <div
                        className={`h-2 w-2 rounded-full bg-${noColor1}`}
                      ></div>
                    </div>
                    <p className="text-xl font-semibold">No</p>
                  </div>
                </div>
              </div>

              <div className="grid md:grid-cols-2 grid-cols-1">
                <div className="text-headingColor text-xl font-normal flex">
                  Do you need charging costs reimbursed?
                </div>
                <div className="flex gap-[6rem] md:justify-end md:mr-12 md:mt-0 mt-5">
                  <div className="flex gap-5 justify-center items-center">
                    <div
                      onClick={handleYesClick2}
                      className="h-5 w-5 border-[3px] flex justify-center items-center border-primaryColor rounded-full"
                    >
                      <div
                        className={`h-2 w-2 rounded-full bg-${yesColor2}`}
                      ></div>
                    </div>
                    <p className="text-xl font-semibold">Yes</p>
                  </div>

                  <div className="flex gap-5 justify-center items-center">
                    <div
                      onClick={handleNoClick2}
                      className="h-5 w-5 border-[3px] flex justify-center items-center border-primaryColor rounded-full"
                    >
                      <div
                        className={`h-2 w-2 rounded-full bg-${noColor2}`}
                      ></div>
                    </div>
                    <p className="text-xl font-semibold">No</p>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="flex justify-between items-center w-[73%] mx-auto mt-[7rem] pb-[1rem] media-control-arrowbtn-div">
            <div
              onClick={HomeQustionBackButton}
              className="flex items-center gap-4 cursor-pointer"
            >
              <LeftArrowSvg />
              <p className="text-xl font-semibold">Back</p>
            </div>
            <button
              type="button"
              onClick={onClick}
              className={`bg-primaryColor font-semibold text-white px-[3.1rem] py-2 rounded ${areFieldsEmpty() ? 'cursor-not-allowed' : ''}`}
              disabled={areFieldsEmpty()}
            >
              Next
            </button>
          </div>

          <div className="flex-col items-center gap-8 hidden mt-[3rem] media-control-btn">
            <button
              onClick={onClick}
              className={`w-[80%] py-4 bg-primaryColor text-[20px] rounded text-white font-semibold ${areFieldsEmpty() ? 'cursor-not-allowed' : ''}`}
              disabled={areFieldsEmpty()}
            >
              Next
            </button>
            <button
              onClick={HomeQustionBackButton}
              className="w-[80%] py-4 bg-progressColor text-[20px] rounded text-white font-semibold"
            >
              Back
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default HomeQuestion;
