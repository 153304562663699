import React from "react";

function LeftArrowSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      viewBox="0 0 26 23"
      fill="none"
    >
      <path
        d="M2.20344 9.91846H24.2409C24.5332 9.91846 24.8134 10.0345 25.0201 10.2412C25.2267 10.4478 25.3428 10.7281 25.3428 11.0203C25.3428 11.3126 25.2267 11.5928 25.0201 11.7995C24.8134 12.0061 24.5332 12.1222 24.2409 12.1222H2.20344C1.9112 12.1222 1.63094 12.0061 1.42429 11.7995C1.21765 11.5928 1.10156 11.3126 1.10156 11.0203C1.10156 10.7281 1.21765 10.4478 1.42429 10.2412C1.63094 10.0345 1.9112 9.91846 2.20344 9.91846Z"
        fill="#141414"
      />
      <path
        d="M2.6619 11.0201L11.8008 20.1569C12.0078 20.3638 12.124 20.6444 12.124 20.937C12.124 21.2296 12.0078 21.5102 11.8008 21.7171C11.5939 21.924 11.3133 22.0403 11.0207 22.0403C10.7281 22.0403 10.4475 21.924 10.2406 21.7171L0.323717 11.8003C0.221103 11.6979 0.139691 11.5763 0.0841421 11.4425C0.0285933 11.3086 0 11.1651 0 11.0201C0 10.8752 0.0285933 10.7317 0.0841421 10.5978C0.139691 10.464 0.221103 10.3424 0.323717 10.24L10.2406 0.323139C10.4475 0.116236 10.7281 0 11.0207 0C11.3133 0 11.5939 0.116236 11.8008 0.323139C12.0078 0.530042 12.124 0.810662 12.124 1.10327C12.124 1.39587 12.0078 1.67649 11.8008 1.88339L2.6619 11.0201Z"
        fill="#141414"
      />
    </svg>
  );
}

export default LeftArrowSvg;
