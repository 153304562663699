import React, { useEffect, useState } from "react";
import "../Custom-CSS/ChargingStation.css";
import {
  CarWashSvg,
  ChargerTypeSvg,
  LighteningSvg,
  MapCardPointerSvg,
  MapCardPointerSvg2,
  MarkerSvg,
  ParkingSvg,
  SpoonFolkSvg,
  StarSvg,
  TimeSvg,
} from "../Features/Assets/SvgIcons";
import { SEO } from "../Features/UI";
import {
  MapCardImage,
  MapMarker2,
} from "../Features/Assets/images/imageImport";
import ToiletGenderSvg from "../Features/Assets/SvgIcons/ToiletGenderSvg";
import CloseSvg from "../Features/Assets/SvgIcons/CloseSvg";

const ChargingStations = () => {
  const [isOpen, SetIsOpen] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://api1.tplmaps.com/js-api-v2/assets/tplmaps.js?api_key=$2a$10$DTYD70WKuSr4Er7vSj8ZuebBRChI5wo7vdakw5aKkvTl4sdbDdNsm`;

    script.onload = () => {
      const script2 = document.createElement("script");
      script2.src = `marker.js`;
      const mapOptions = {
        lat: 31.524669696492456,
        lng: 74.37138876452508,
        zoom: 13,
        minZoom: 0,
        maxZoom: 20,
        divID: "map",
      };

      let map;
      let markers = [];

      map = window.TPLMaps.map.initMap(mapOptions);

      const locations = [
        // Davis Road Garih shahu
        {
          lat: 31.56134602703795,
          lng: 74.33853419861738,
          label: "Test location,Lahore",
          icon: MapMarker2, // Replace with the path to your custom icon
          map: map,
        },
        // Sher Ali Road
        {
          lat: 31.52451839703098,
          lng: 74.38028992325293,
          label: "Test location,Lahore",
          icon: MapMarker2, // Replace with the path to your custom icon
          map: map,
        },
        // Girja Chowk
        {
          lat: 31.46802053013481,
          lng: 74.25973594609944,
          label: "Test location,Lahore",
          icon: MapMarker2, // Replace with the path to your custom icon
          map: map,
        },
        // Girja Chowk
        {
          lat: 31.522298993821206,
          lng: 74.37976936812446,
          label: "Test location,Lahore",
          icon: MapMarker2, // Replace with the path to your custom icon
          map: map,
        },
      ];

      locations.forEach((location) => {
        const marker = window.TPLMaps.overlays.point(location);

        // Add an onClick event listener to the marker
        marker.on("click", () => {
          SetIsOpen(true);
        });

        markers.push(marker);
        const options = {
          map: map,
          geomObject: marker,
        };
        window.TPLMaps.overlays.addToMap(options);
        map.setView(marker.getLatLng());
      });
    };

    document.body.appendChild(script);

    // Clean up the script tag when the component unmounts
    return () => {
      document.body.removeChild(script);
      delete window.initMap; // Remove the callback function
    };
  }, []);

  const CloseModal = () => {
    SetIsOpen(false);
  };
  return (
    <>
      <SEO
        title="VerteCharge: Charging Stations"
        description="View live details regarding charging station locations, amenities, and available chargers types"
        name="VerteCharge"
        type="article"
        image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
        canonicalUrl={"https://vertecharge.com/charging-stations"}
      />
      <div>
        <div id="map" className="h-[77.5vh] w-full z-0"></div>
        {isOpen && (
          <div className="absolute z-10 top-[4.7rem] media-control-top bg-white shadow-lg md:h-[77%] h-[79%] no-scrollbar overflow-auto left-0 lg:w-[30%] md:w-[40%] w-full ">
            <div className="relative">
              <img
                className="w-full h-auto"
                src={MapCardImage}
                alt="MapCardImage"
              />
              <div className="absolute top-2 right-[20px] z-10">
                <CloseSvg onClick={CloseModal} />
              </div>
              <div className="absolute inset-0 bg-black opacity-50"></div>
            </div>
            <div className="px-6">
              <div>
                <h1 className="font-bold text-xl mt-2">
                  Roadstation Charging Station
                </h1>
                <div className="flex justify-end">
                  <span className="text-primaryColor text-[12px] border-[1px] rounded-full py-[2px] px-3 border-primaryColor">
                    Available
                  </span>
                </div>
              </div>

              <div className=" flex flex-col gap-1">
                <div className="flex items-center gap-2">
                  <MapCardPointerSvg />
                  <p className="text-textColor text-[14px]">
                    A3213,Westminister London
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <p className="text-headingColor font-bold text-[12px]">5.0</p>
                  <div className="flex items-center justify-center">
                    <StarSvg />
                    <StarSvg />
                    <StarSvg />
                    <StarSvg />
                    <StarSvg />
                  </div>
                  <p className="text-textColor text-[12px]">(25 Reviews)</p>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <LighteningSvg height="32" width="13" />
                    <p className="text-[14px] text-headingColor">07Kw</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <MapCardPointerSvg2 />
                    <p className="text-[14px] text-headingColor">
                      3.5Km 50 min
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <TimeSvg />
                    <p className="text-[14px] text-headingColor">
                      3.5Km 50 min
                    </p>
                  </div>
                </div>
              </div>

              {/* Divider */}
              <div className=" bg-borderColor h-[1px] my-3 w-full"></div>

              <div>
                <h1 className="font-bold text-[16px]">Facilities</h1>
                <div className="flex justify-between mt-2">
                  <div className="flex flex-col items-center">
                    <SpoonFolkSvg />
                    <p className="font-bold text-[14px]">Cafe</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <CarWashSvg />
                    <p className="font-bold text-[14px]">Car Wash</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <ToiletGenderSvg />
                    <p className="font-bold text-[14px]">Toilet</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <ParkingSvg />
                    <p className="font-bold text-[14px]">Parking</p>
                  </div>
                </div>
              </div>

              {/* Divider */}
              <div className=" bg-borderColor h-[1px] my-3 w-full"></div>

              <div>
                <h1 className="font-bold text-[16px]">
                  Available Chargers{" "}
                  <span className="text-primaryColor">2</span>
                  /2
                </h1>
                <div className="flex gap-5 mt-3 mb-3">
                  <div className="border-primaryColor border-[1px] rounded">
                    <p className="bg-primaryColor text-white py-2 text-center">
                      Charger A
                    </p>
                    <div className="flex flex-col items-center gap-2 py-2 px-7">
                      <ChargerTypeSvg />
                      <p className="text-headingColor font-bold text-center">
                        AC Type-2
                      </p>
                      <p className="text-textColor">16 kW</p>
                    </div>
                  </div>
                  <div className="border-primaryColor border-[1px] rounded">
                    <p className="bg-primaryColor text-white py-2 text-center">
                      Charger B
                    </p>
                    <div className="flex flex-col items-center gap-2 py-2 px-7">
                      <ChargerTypeSvg />
                      <p className="text-headingColor font-bold text-center">
                        AC Type-2
                      </p>
                      <p className="text-textColor">16 kW</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="bg-white">
          <div className="md:flex flex-row justify-between lg:w-[80%] w-[90%] mx-auto py-5">
            <div className="flex items-center gap-5">
              <MarkerSvg />
              <p className="font-normal text-base">
                Available Charging Stations
              </p>
            </div>
            <div className="flex items-center gap-5">
              <MarkerSvg color="#B55B52" />
              <p className="font-normal text-base">
                Non Available Charging Stations
              </p>
            </div>
            <div className="flex items-center gap-5">
              <MarkerSvg color="#EB9B3C" />
              <p className="font-normal text-base">
                Partially Available Stations
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChargingStations;
