import React from "react";
import { PrivacyPolicyImage } from "../Features/Assets/images/imageImport";
import { SEO } from "../Features/UI";

const PrivacyPolicy = () => {
  return (
    <div class="relative">
      <SEO
        title="VerteCharge: Privacy Policy"
        description="This policy covers what personal information VerteCharge collects, how that information is collected and why we need it."
        name="VerteCharge"
        type="article"
        image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
        canonicalUrl={"https://vertecharge.com/privacy-policy"}
      />
      <div className="relative">
        <img
          class="w-full h-48 sm:h-80"
          src={PrivacyPolicyImage}
          alt="Privacy Policy"
        />
        <div class="absolute text-3xl sm:text-5xl font-extrabold text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
          Privacy Policy
        </div>
      </div>
      <div className={"w-5/6 mx-auto my-10 text-justify"}>
        <div className="font-bold my-5">Last Updated: 23/11/2023 </div>
        <div>
          At VerteCharge, we are committed to safeguarding your privacy and
          handling your information responsibly. This privacy policy outlines
          how we collect, use, and protect your data in conjunction with your
          use of the VerteCharge app and related services. By utilizing our app,
          you agree to the terms laid out in this policy.
        </div>
        <div className="font-bold my-5">Information We Collect</div>
        <div>
          Account Registration Information: When you sign up for VerteCharge, we
          may collect your first name, last name, email, phone number, and
          password. This data is essential for account creation and access to
          our services.
        </div>
        <div className="mt-2">
          User Preferences: We gather information about your location to display
          nearby charging stations and facilitate spot reservations.
          Additionally, we may ask for Bluetooth permissions for future pairing
          with EV chargers (currently under development).
        </div>
        <div className="mt-2">
          User Interaction: Any updates you make to your user details,
          selections for light/dark mode, and chosen languages (English, Urdu,
          Arabic) within the app are stored to personalize your experience.
        </div>
        <div className="font-bold my-5">Sign-In with Third-Party Services</div>
        <div>
          VerteCharge offers the convenience of signing in using third-party
          services such as Google, Apple, and Facebook. When you choose to sign
          in through these platforms, we may collect certain information from
          your chosen third-party account to create and authenticate your
          VerteCharge account. This information might include your basic profile
          details from the respective service provider (e.g., your name and
          email address).
        </div>
        <div className="mt-2">
          Please note that by using third-party sign-in options, you authorize
          VerteCharge to access and use the information available from these
          services for the sole purpose of facilitating your access to our app
          and its services. We treat this information with the same level of
          confidentiality and security as any other data collected directly
          through our app.
        </div>
        <div className="font-bold my-5">How We Use Your Information</div>
        <div>
          VerteCharge utilizes the collected information for the following
          purposes:
          <ul className="mt-2">
            <li className="mt-1">
              - Facilitating account creation and service provision
            </li>
            <li className="mt-1">
              - Enabling access to charging stations and their details
            </li>
            <li className="mt-1">- Managing and updating user preferences</li>
            <li className="mt-1">
              - Improving app functionality and user experience
            </li>
            <li className="mt-1">
              - Communicating station-related information and booking details
            </li>
            <li className="mt-1">
              - Preparing for future Bluetooth pairing features
            </li>
          </ul>
        </div>
        <div className="font-bold my-5">Sharing Your Information</div>
        <div>
          We do not sell your personal information. However, we may share
          necessary data with service providers, affiliates, and partners to
          deliver our services effectively and enhance your experience with
          VerteCharge. This sharing occurs in adherence to privacy and data
          protection standards.
        </div>
        <div className="font-bold my-5">Your Choices</div>
        <div>
          You retain the right to modify certain information within the app and
          manage your account settings. Should you wish to unsubscribe from
          specific communications, you can adjust your preferences accordingly.
        </div>
        <div className="font-bold my-5">Security Measures</div>
        <div>
          VerteCharge employs robust security measures to protect your data, but
          please note that no method of transmission over the internet is
          entirely secure.
        </div>
        <div className="font-bold my-5">Children’s Privacy</div>
        <div>
          Our services are intended for users who are legally permitted to
          drive, typically individuals over the age of 16. We do not knowingly
          collect information from individuals under this age.
        </div>
        <div className="font-bold my-5">Changes to Our Privacy Policy</div>
        <div>
          We may update our privacy policy periodically. By continuing to use
          VerteCharge, you acknowledge and agree to the most recent version of
          this policy.
        </div>
        <div className="font-bold my-5">Contact Us</div>
        <div>
          For any inquiries or concerns about our privacy practices, please
          contact us at{" "}
          <a
            href={`mailto:support@rhodium-tech.com`}
            className={"text-green-500 font-bold"}
          >
            support@rhodium-tech.com
          </a>{" "}
          or call us at{" "}
          <a
            href={`phone:+923357463486`}
            className={"text-green-500 font-bold"}
          >
            (+92) 335 746 3486
          </a>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
