import React from "react";

function ParkingSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 51 51"
      fill="none"
    >
      <path
        d="M25.6666 5.3479C14.3891 5.3479 5.16211 14.5749 5.16211 25.8524C5.16211 37.1298 14.3891 46.3568 25.6666 46.3568C36.944 46.3568 46.171 37.1298 46.171 25.8524C46.171 14.5749 36.944 5.3479 25.6666 5.3479ZM25.6666 42.2559C16.6446 42.2559 9.263 34.8743 9.263 25.8524C9.263 16.8304 16.6446 9.44879 25.6666 9.44879C34.6885 9.44879 42.0702 16.8304 42.0702 25.8524C42.0702 34.8743 34.6885 42.2559 25.6666 42.2559Z"
        fill="#34B44A"
      />
      <path
        d="M28.7426 15.6001H19.5156V23.8019V29.9532V36.1046H23.6165V29.9532H28.7426C32.6385 29.9532 35.9192 26.6725 35.9192 22.7767C35.9192 18.8808 32.6385 15.6001 28.7426 15.6001ZM28.7426 25.8523H23.6165V23.8019V19.701H28.7426C30.383 19.701 31.8183 21.1363 31.8183 22.7767C31.8183 24.417 30.383 25.8523 28.7426 25.8523Z"
        fill="#34B44A"
      />
    </svg>
  );
}

export default ParkingSvg;
