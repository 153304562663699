import React from "react";
import { PrivacyPolicyImage } from "../Features/Assets/images/imageImport";
import { SEO } from "../Features/UI";

const PricingPolicy = () => {
  return (
    <div class="relative">
      <SEO
        title="VerteCharge: Pricing Policy"
        description="This policy outlines our pricing structure, payment terms, and any associated fees."
        name="VerteCharge"
        type="article"
        image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
        canonicalUrl={"https://vertecharge.com/pricing-policy"}
      />
      <div className="relative">
        <img
          class="w-full h-48 sm:h-80"
          src={PrivacyPolicyImage}
          alt="Pricing Policy"
        />
        <div class="absolute text-3xl sm:text-5xl font-extrabold text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
          Pricing Policy
        </div>
      </div>
      <div className={"w-5/6 mx-auto my-10 text-justify"}>
        <div className="font-bold my-5">Last Updated: 09/07/2024 </div>
        <div>
          Verte Charge aims to provide transparent, fair, and competitive
          pricing for our services. This policy outlines our pricing structure,
          payment terms, and any associated fees.
        </div>
        <div className="font-bold my-5">Pricing Structure</div>
        <div className="mt-2">
          Consultation Services: Initial consultation sessions are provided at
          no cost. Subsequent sessions will be charged at an hourly rate of
          ________.
        </div>
        <div className="mt-2">
          Project Implementation: Pricing for project implementation will be
          based on a detailed quote provided after the initial consultation. The
          quote will include costs for equipment, installation, and labor.
        </div>
        <div className="mt-2">
          Maintenance and Support: Ongoing maintenance and support services are
          available on a subscription basis or at an hourly rate. Subscription
          plans will be detailed in the service agreement.
        </div>
        <div className="font-bold my-5">Payment Terms</div>
        <div className="mt-2">
          Invoices: Invoices will be issued upon completion of services or as
          specified in the service agreement.{" "}
        </div>
        <div className="mt-2">
          Payment Methods: We accept payments via bank transfer, credit card.{" "}
        </div>
        <div className="mt-2">
          Payment Due Date: Payments are due within 30 days of the invoice date.{" "}
        </div>
        <div className="mt-2">
          Late Payments: Late payments will incur a fee of 5% per month.{" "}
        </div>
        <div className="font-bold my-5">Additional Fees</div>
        <div className="mt-2">
          Late Payments: Late payments will incur a fee of 5% per month.{" "}
        </div>
        <div className="mt-2">
          Customization Fees: Customization of services or products will be
          charged at an additional rate agreed upon prior to the commencement of
          work.
        </div>
        <div className="font-bold my-5">Contact Us</div>
        <div>
          For any questions or further clarifications, please feel free to reach
          out at our office at 17-Lawrence Road, Jinnah Town, Lahore.
          <div>
            Or contact us at our customer support team at{" "}
            <a
              href={`mailto:support@rhodium-tech.com`}
              className={"text-green-500 font-bold"}
            >
              support@rhodium-tech.com
            </a>{" "}
            or call us at{" "}
            <a
              href={`phone:+923357463486`}
              className={"text-green-500 font-bold"}
            >
              (+92) 335 746 3486
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPolicy;
