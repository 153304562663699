import React, { useEffect, useState } from "react";
import { LeftArrowSvg } from "../Features/Assets/SvgIcons";
import "../Custom-CSS/Lets-Talk.css";

function BusinessQuestion({
  ShowBusinessQuestion,
  BQNext,
  BQBackButton,
  BQFormData,
}) {
  const [yesColor, setYesColor] = useState("white");
  const [noColor, setNoColor] = useState("white");

  const [formData, setFormData] = useState({
    preferred_power_output: "",
    charging_stations_number: "",
    verte_charge_plans: "",
    charging_stations_place: "",
    purchase_timeline: "",
  });

  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => {
      const newData = { ...prevData };
      if (fieldName === "verte_charge_plans") {
        newData[fieldName] = value === "Yes"; // Store boolean values
      } else {
        newData[fieldName] = value;
      }
      return newData;
    });
  };

  useEffect(() => {
    BQFormData({
      preferred_power_output: formData.preferred_power_output,
      charging_stations_number: formData.charging_stations_number,
      verte_charge_plans: formData.verte_charge_plans,
      charging_stations_place: formData.charging_stations_place,
      purchase_timeline: formData.purchase_timeline,
    });
  }, [formData]);

  const handleYesClick = () => {
    setYesColor("primaryColor");
    setNoColor("white");
    handleInputChange("verte_charge_plans", "Yes");
  };

  const handleNoClick = () => {
    setNoColor("primaryColor");
    setYesColor("white");
    handleInputChange("verte_charge_plans", "No");
  };

  const areFieldsEmpty = () => {
    return Object.values(formData).some((value) => value === "");
  };

  return (
    <>
      {ShowBusinessQuestion && (
        <div className="my-[3rem]">
          <div className="flex justify-center">
            <div className="lg:w-[55%]  grid md:grid-cols-3 grid-cols-2 gap-8 mediaControlGrid mediaControlWidth">
              <div className=" flex flex-col gap-2 ">
                <p className="font-bold text-xl text-headingColor">
                  1. Charging Type
                </p>
                <div className="bg-primaryColor h-1 rounded"></div>
              </div>
              <div className=" flex flex-col gap-2 ">
                <p className="font-bold text-xl text-headingColor">
                  2. Recommendation
                </p>
                <div className="bg-progressColor h-1 rounded"></div>
              </div>
              <div className=" flex flex-col gap-2 ">
                <p className="font-bold text-xl text-headingColor">
                  3. Your details
                </p>
                <div className="bg-progressColor h-1 rounded"></div>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-[2.5rem]">
            <p className="font-normal sm:text-2xl text-xl text-headingColor">
              Your Preferences
            </p>
          </div>

          <div className="w-[80%] mx-auto flex justify-center mt-[1rem] mb-[3rem]">
            <p className="font-medium sm:text-4xl text-3xl text-headingColor">
              Tell us about us your business charging needs
            </p>
          </div>

          <div className="flex flex-col gap-8">
            <div className="lg:w-[75%] w-[80%] mx-auto grid md:grid-cols-2 grid-cols-1 ">
              <div className="text-headingColor text-xl font-normal flex">
                Select your preferred power output?
              </div>
              <div className="flex gap-4 md:justify-end">
                <input
                  className="sm:w-[65%] w-[90%] input-width border py-4 ps-3 bg-transparent  rounded outline-none md:mt-0 mt-5"
                  value={formData.preferred_power_output}
                  onChange={(e) =>
                    handleInputChange("preferred_power_output", e.target.value)
                  }
                />
              </div>
            </div>

            <div className="lg:w-[75%] w-[80%] mx-auto grid md:grid-cols-2 grid-cols-1">
              <div className="text-headingColor text-xl font-normal flex">
                How many charging stations do you need?
              </div>
              <div className="flex gap-4 md:justify-end">
                <input
                  className="sm:w-[65%] w-[90%] input-width border ps-3 py-4 bg-transparent  rounded outline-none md:mt-0 mt-5"
                  value={formData.charging_stations_number}
                  onChange={(e) =>
                    handleInputChange(
                      "charging_stations_number",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>

            <div className="lg:w-[75%] w-[80%] mx-auto grid md:grid-cols-2 grid-cols-1">
              <div className="text-headingColor  text-xl font-normal">
                Are you interested in{" "}
                <span className="font-bold">VerteCharge</span> plans?
              </div>
              <div className="flex lg:justify-center md:justify-end md:ps-[22%] md:mt-0 mt-5 lg:gap-[6rem] gap-[4rem]">
                <div className="flex gap-5 justify-center items-center">
                  <div
                    onClick={handleYesClick}
                    className="h-5 w-5 border-[3px] border-primaryColor flex items-center justify-center  rounded-full"
                  >
                    <div
                      className={`h-2 w-2 rounded-full bg-${yesColor}`}
                    ></div>
                  </div>
                  <p className="text-xl font-semibold">Yes</p>
                </div>

                <div className="flex gap-5 justify-center items-center">
                  <div
                    onClick={handleNoClick}
                    className="h-5 w-5 border-[3px] border-primaryColor flex items-center justify-center rounded-full"
                  >
                    <div className={`h-2 w-2 rounded-full bg-${noColor}`}></div>
                  </div>
                  <p className="text-xl font-semibold">No</p>
                </div>
              </div>
            </div>

            <div className="lg:w-[75%] w-[80%] mx-auto grid md:grid-cols-2 grid-cols-1">
              <div className="text-headingColor text-xl font-normal flex">
                Where will be the charging station be placed
              </div>
              <div className="flex gap-4 md:justify-end">
                <input
                  className="sm:w-[65%] w-[90%] input-width border ps-3 py-4 bg-transparent  rounded outline-none md:mt-0 mt-5"
                  value={formData.charging_stations_place}
                  onChange={(e) =>
                    handleInputChange("charging_stations_place", e.target.value)
                  }
                />
              </div>
            </div>

            <div className="lg:w-[75%] w-[80%] mx-auto grid md:grid-cols-2 gris-cols-1 items-center">
              <div className="text-headingColor text-xl  font-normal flex ">
                What your purchase timeline?
              </div>
              <div className="flex gap-4 md:justify-end">
                <input
                  className="sm:w-[65%] w-[90%] input-width border py-4 ps-3 bg-transparent  rounded outline-none md:mt-0 mt-5"
                  value={formData.purchase_timeline}
                  onChange={(e) =>
                    handleInputChange("purchase_timeline", e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center w-[73%] mx-auto mt-[7rem] pb-[1rem] media-control-arrowbtn-div">
            <div
              onClick={BQBackButton}
              className="flex items-center gap-4 cursor-pointer"
            >
              <LeftArrowSvg />
              <p className="text-xl font-semibold">Back</p>
            </div>
            <button
              onClick={BQNext}
              className={`bg-primaryColor font-semibold text-white px-[3.1rem] py-2 rounded ${areFieldsEmpty() ? 'cursor-not-allowed' : ''}`}
              disabled={areFieldsEmpty()}
            >
              Next
            </button>
          </div>

          <div className="flex-col items-center gap-8 hidden mt-[3rem] media-control-btn">
            <button
              onClick={BQNext}
              className={`w-[80%] py-4 bg-primaryColor text-[20px] rounded text-white font-semibold  ${areFieldsEmpty() ? 'cursor-not-allowed' : ''}`}
              disabled={areFieldsEmpty()}
            >
              Next
            </button>
            <button
              onClick={BQBackButton}
              className="w-[80%] py-4 bg-progressColor text-[20px] rounded text-white font-semibold"
            >
              Back
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default BusinessQuestion;
