import React, { useState } from "react";
import "../Custom-CSS/HomeCharging.css";
import {
  home_charging_header_img,
  home_charging_car_charging,
  home_charging_charging_limit,
  home_charging_elevate_img,
} from "../Features/Assets/images/imageImport";
import { SEO } from "../Features/UI";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function HomeCharging() {
  // State
  const [email, setEmail] = useState("");

  // use for the navigation
  const navigate = useNavigate();

  // BaseUrl
  const BASE_URL = process.env.REACT_APP_RESERVATION_URL;

  // Check if the email is validate
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  // function triggers on the lets talk button
  const handleLetsTalk = async () => {
    if (email) {
      if (validateEmail(email)) {
        try {
          const response = await axios.get(
            `${BASE_URL}/email/subscribe?email=${email}`
          );
          if (response.status === 200) {
            navigate("/lets-talk");
          }
        } catch (error) {
          throw error;
        }
      } else {
        alert("Enter the correct email");
      }
    } else {
      alert("Enter you email first");
    }
  };

  // function to handle the email
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };



  return (
    <>
      <SEO
        title="VerteCharge: Home Charging Solutions"
        description="Manage charging sessions remotely, view charging history, and schedule your charging during off-peak hours where available."
        name="VerteCharge"
        type="article"
        image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
        canonicalUrl={"https://vertecharge.com/home-charging"}
      />

      {/* Header */}
      <div className="flex justify-center mt-12 mb-8 md:mt-16 md:mb-12">
        <div className="w-11/12 lg:w-4/5 flex justify-center">
          <div className="text-center">
            <p className="text-primaryColor text-base leading-6 font-semibold md:text-lg md:leading-7">
              Home Chargers
            </p>
            <p className="text-3xl leading-tight font-semibold text-[#101828] my-3 md:text-4xl md:leading-snug">
              Power Your Drive with Ease
            </p>
            <p className="text-lg leading-6 font-normal text-[#101828] md:text-xl md:leading-7">
              Effortless Convenience at your fingertips
            </p>
          </div>
        </div>
      </div>

      {/* Header Image */}
      <div className="flex justify-center">
        <img
          className="w-[90%] max-w-auto h-full"
          src={home_charging_header_img}
          alt="home_charging_header_img"
        />
      </div>

      {/* Embrace the future of the electric Vehicle */}
      <div className="flex justify-center mt-[4rem] mb-[3rem]">
        <div className="w-[90%] md:w-[80%] lg:w-[70%]">
          <h1 className="font-semibold text-[2rem] md:text-[3rem] leading-tight md:leading-[3.75rem] text-center text-[#333333]">
            Embrace the Future of Electric Vehicle charging with our Intuitive
            App
          </h1>
        </div>
      </div>

      {/* Plug & Charge Section */}
      <div className="flex justify-center md:pt-[8.125rem] md:pb-[9.125rem]">
        <div className="w-[90%] grid md:grid-cols-2 grid-cols-1">
          {/* Plug & Charge */}
          <div className="flex flex-col md:flex-row">
            <div className="md:ps-[2rem]">
              <div>
                <h1 className="font-semibold text-[2rem] md:text-[3rem] leading-tight md:leading-[3.75rem] text-[#333333]">
                  Plug & Charge
                </h1>
                <p className="font-normal text-[1rem] md:text-[1.25rem] leading-snug md:leading-[1.875rem] text-[#333333]">
                  Simply plug in and power up
                </p>
              </div>
              <div className="mt-8 md:mt-[3rem]">
                <h3 className="font-semibold text-[1.25rem] md:text-[1.5rem] leading-snug md:leading-[1.875rem] text-primaryColor">
                  Instant Activation
                </h3>
                <p className="font-medium text-[0.875rem] md:text-[1rem] leading-snug md:leading-[1.25rem] text-[#333333] mt-3">
                  Plug in your car and our intelligent system starts charging
                  automatically
                </p>
              </div>
              <div className="mt-8 md:mt-[2.5rem]">
                <h3 className="font-semibold text-[1.25rem] md:text-[1.5rem] leading-snug md:leading-[1.875rem] text-primaryColor">
                  No Hassle, No Worry
                </h3>
                <p className="font-medium text-[0.875rem] md:text-[1rem] leading-snug md:leading-[1.25rem] text-[#333333] mt-3">
                  Enjoy a stress-free charging experience without the need for
                  manual settings
                </p>
              </div>
              <div className="mt-8 md:mt-[2.5rem]">
                <h3 className="font-semibold text-[1.25rem] md:text-[1.5rem] leading-snug md:leading-[1.875rem] text-primaryColor">
                  Real-Time Monitoring
                </h3>
                <p className="font-medium text-[0.875rem] md:text-[1rem] leading-snug md:leading-[1.25rem] text-[#333333] mt-3">
                  Track your charging status directly from your app with live
                  updates
                </p>
              </div>
              <div className="mt-8 md:mt-[2.5rem]">
                <h3 className="font-semibold text-[1.25rem] md:text-[1.5rem] leading-snug md:leading-[1.875rem] text-primaryColor">
                  Maximum Efficiency
                </h3>
                <p className="font-medium text-[0.875rem] md:text-[1rem] leading-snug md:leading-[1.25rem] text-[#333333] mt-3">
                  Our technology ensures your car charges at optimal speeds for
                  the best performance
                </p>
              </div>
            </div>
          </div>

          {/* Car Charging Image */}
          <div className="relative md:block flex justify-center md:mt-0 mt-[-2rem]">
            <img
              className="md:absolute top-[-12rem] img right-0"
              src={home_charging_car_charging}
              alt="home_charging_car_charging"
            />
          </div>
        </div>
      </div>

      {/* Schedule Charging */}
      <div className="flex justify-center pt-10  md:py-[7.5625rem] bg-[#333333]">
        <div className="w-[90%] grid md:grid-cols-2 grid-cols-1">
          {/* Charging Limit Image */}
          <div className="relative md:block flex justify-center md:order-1 order-2">
            <img
              className="md:absolute top-[-8rem] left-[-2rem] img-schedule"
              src={home_charging_charging_limit}
              alt="home_charging_charging_limit"
            />
          </div>
          {/* Schedule Charging */}
          <div className="flex flex-col md:flex-row  md:order-2 order-1">
            <div className="md:ps-[2rem]">
              <div>
                <h1 className="font-semibold text-[2rem] md:text-[3rem] leading-tight md:leading-[3.75rem] text-[#ffffff]">
                  Schedule Charging
                </h1>
                <p className="font-normal text-[1rem] md:text-[1.25rem] leading-snug md:leading-[1.875rem] text-[#ffffff]">
                  Charge Smart, Save Smart
                </p>
              </div>
              <div className="mt-8 md:mt-[3rem]">
                <h3 className="font-semibold text-[1.25rem] md:text-[1.5rem] leading-snug md:leading-[1.875rem] text-[#ADFFBB]">
                  Flexible Scheduling
                </h3>
                <p className="font-medium text-[0.875rem] md:text-[1rem] leading-snug md:leading-[1.25rem] text-[#ffffff] mt-3">
                  Choose your preferred charging times to fit your lifestyle and
                  daily routines.
                </p>
              </div>
              <div className="mt-8 md:mt-[2.5rem]">
                <h3 className="font-semibold text-[1.25rem] md:text-[1.5rem] leading-snug md:leading-[1.875rem] text-[#ADFFBB]">
                  Energy Savings
                </h3>
                <p className="font-medium text-[0.875rem] md:text-[1rem] leading-snug md:leading-[1.25rem] text-[#ffffff] mt-3">
                  Schedule charging during off-peak hours to save on electricity
                  costs.
                </p>
              </div>
              <div className="mt-8 md:mt-[2.5rem]">
                <h3 className="font-semibold text-[1.25rem] md:text-[1.5rem] leading-snug md:leading-[1.875rem] text-[#ADFFBB]">
                  Remote Management
                </h3>
                <p className="font-medium text-[0.875rem] md:text-[1rem] leading-snug md:leading-[1.25rem] text-[#ffffff] mt-3">
                  Manage your charging sessions from anywhere, anytime through
                  our app.
                </p>
              </div>
              <div className="mt-8 md:mt-[2.5rem]">
                <h3 className="font-semibold text-[1.25rem] md:text-[1.5rem] leading-snug md:leading-[1.875rem] text-[#ADFFBB]">
                  Eco-Friendly Choices
                </h3>
                <p className="font-medium text-[0.875rem] md:text-[1rem] leading-snug md:leading-[1.25rem] text-[#ffffff] mt-3">
                  Align your charging with renewable energy availability for a
                  greener footprint.
                </p>
              </div>
              <div className="mt-8 md:mt-[2.5rem]">
                <h3 className="font-semibold text-[1.25rem] md:text-[1.5rem] leading-snug md:leading-[1.875rem] text-[#ADFFBB]">
                  Notifications & Alerts
                </h3>
                <p className="font-medium text-[0.875rem] md:text-[1rem] leading-snug md:leading-[1.25rem] text-[#ffffff] mt-3">
                  Get reminders and updates to ensure your car is always ready
                  when you are.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Elevate your ev charging game with verte charge  home charger */}
      <div className="flex justify-center mt-[4rem] mb-[3rem]">
        <div className="w-[90%] md:w-[80%] lg:w-[70%]">
          <h1 className="font-semibold text-[2rem] md:text-[3rem] leading-tight md:leading-[3.75rem] text-center text-[#333333]">
            Elevate your EV charging game with Verte Charge Home Charger
          </h1>
        </div>
      </div>

      {/* wifi ready section */}
      <div className="flex justify-center">
        <div className="w-[90%] grid md:grid-cols-2 grid-cols-1">
          <div className="flex items-center">
            <div className="md:ps-[2rem]">
              <div>
                <h3 className="font-semibold text-[1.5rem] leading-[1.875rem] text-primaryColor ">
                  Wi-Fi Ready
                </h3>
                <p className="font-medium text-[1rem] leading-[1.25rem] text-[#333333] mt-3">
                  Stay connected and in control with our Wi-Fi enabled chargers.
                  Monitor and manage your charging sessions anytime, anywhere.
                </p>
              </div>
              <div className="mt-[2.5rem]">
                <h3 className="font-semibold text-[1.5rem] leading-[1.875rem] text-primaryColor ">
                  Unrivalled Compatibility
                </h3>
                <p className="font-medium text-[1rem] leading-[1.25rem] text-[#333333] mt-3">
                  Our chargers are compatible with all major electric vehicle
                  brands, ensuring a seamless charging experience for any EV.
                </p>
              </div>
              <div className="mt-[2.5rem]">
                <h3 className="font-semibold text-[1.5rem] leading-[1.875rem] text-primaryColor ">
                  Flexible Installation
                </h3>
                <p className="font-medium text-[1rem] leading-[1.25rem] text-[#333333] mt-3">
                  Whether wall-mounted or freestanding, our chargers are
                  designed to fit any space effortlessly. Enjoy hassle-free
                  installation tailored to your home.
                </p>
              </div>
              <div className="mt-[2.5rem]">
                <h3 className="font-semibold text-[1.5rem] leading-[1.875rem] text-primaryColor ">
                  Charge Faster
                </h3>
                <p className="font-medium text-[1rem] leading-[1.25rem] text-[#333333] mt-3">
                  Boost your EV's battery with our high-speed charging
                  technology. Spend less time waiting and more time driving.
                </p>
              </div>
              <div className="mt-[2.5rem]">
                <h3 className="font-semibold text-[1.5rem] leading-[1.875rem] text-primaryColor ">
                  Remote Management
                </h3>
                <p className="font-medium text-[1rem] leading-[1.25rem] text-[#333333] mt-3">
                  Use our intuitive app to schedule, start, or stop charging
                  remotely. Perfect for those on-the-go moments.
                </p>
              </div>
              <div className="mt-[2.5rem]">
                <h3 className="font-semibold text-[1.5rem] leading-[1.875rem] text-primaryColor ">
                  Smart Savings
                </h3>
                <p className="font-medium text-[1rem] leading-[1.25rem] text-[#333333] mt-3">
                  Optimise your energy usage and save on costs by scheduling
                  charges during off-peak hours with our smart scheduling
                  features.
                </p>
              </div>
            </div>
          </div>
          {/* Car Charging Image */}
          <div className="relative flex md:justify-end justify-center md:mt-0 mt-10">
            <img
              src={home_charging_elevate_img}
              alt="home_charging_elevate_img"
            />
          </div>
        </div>
      </div>

      {/* Get in touch with us */}
      <div className="flex justify-center md:my-[6rem] my-14">
        <div className="w-[90%]">
          <h1 className="text-center font-semibold text-2xl sm:text-3xl md:text-4xl lg:text-5xl mt-3 text-whiteLabelText">
            Get in touch with us
          </h1>
          <p className="text-whiteLabelGrayText text-center mt-6 mb-10 font-normal text-base sm:text-lg md:text-xl">
            We're here to help you Unlock and Personalise our Features
            effortlessly
          </p>
          <div className="flex flex-col items-center">
            <div className="sm:w-auto w-full">
              <div className="flex sm:gap-4 gap-2">
                <div className="w-full sm:w-[22.5rem] rounded-lg border border-[#D0D5DD] overflow-hidden">
                  <input
                    className="h-full w-full focus:outline-none px-4 py-3"
                    type="text"
                    value={email}
                    onChange={handleEmail}
                    placeholder="Enter your email"
                  />
                </div>
                <div className="flex justify-center sm:mt-0">
                  <button
                    onClick={handleLetsTalk}
                    className="flex items-center justify-center rounded-lg h-auto w-[7rem] bg-primaryColor"
                  >
                    <p className="font-semibold sm:text-base text-[0.875rem] leading-6 py-3 text-white">
                      Let's Talk
                    </p>
                  </button>
                </div>
              </div>

              <p className="text-whiteLabelGrayText font-normal mt-2 text-sm">
                Get in touch with us for white label
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeCharging;
