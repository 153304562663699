import React from "react";

function TwitterSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="22"
      viewBox="0 0 38 30"
      fill="none"
    >
      <path
        d="M36.5204 1.60171C36.5204 1.60171 33.3529 3.47143 31.5917 4.0016C30.6464 2.91539 29.3901 2.14552 27.9926 1.7961C26.5952 1.44668 25.124 1.53457 23.7782 2.04789C22.4324 2.56121 21.2767 3.47519 20.4677 4.66622C19.6586 5.85724 19.235 7.26785 19.2544 8.70726V10.2758C16.4959 10.3473 13.7626 9.73593 11.2978 8.4962C8.83309 7.25647 6.7134 5.42684 5.12757 3.17026C5.12757 3.17026 -1.151 17.2872 12.9758 23.5615C9.74315 25.7542 5.89223 26.8537 1.98828 26.6986C16.1151 34.5413 33.3811 26.6986 33.3811 8.6602C33.3811 8.22414 33.3372 7.78808 33.2556 7.3583C34.8566 5.78034 36.5204 1.60171 36.5204 1.60171Z"
        stroke="white"
        stroke-width="2.35283"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default TwitterSvg;
