import React from "react";
import { PrivacyPolicyImage } from "../Features/Assets/images/imageImport";
import { SEO } from "../Features/UI";

const RefundPolicy = () => {
  return (
    <div class="relative">
      <SEO
        title="VerteCharge: Refund Policy"
        description="This policy outlines our refunding policies."
        name="VerteCharge"
        type="article"
        image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
        canonicalUrl={"https://vertecharge.com/refund-policy"}
      />
      <div className="relative">
        <img
          class="w-full h-48 sm:h-80"
          src={PrivacyPolicyImage}
          alt="Refund Policy"
        />
        <div class="absolute text-3xl sm:text-5xl font-extrabold text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
          Refund Policy
        </div>
      </div>
      <div className={"w-5/6 mx-auto my-10 text-justify"}>
        <div className="font-bold my-5">Last Updated: 09/07/2024 </div>
        <div className="font-bold my-5">Refund Eligibility</div>
        <div className="mt-2">
          Services: Refunds for services are available if the service is
          canceled within 30 of order confirmation.{" "}
        </div>
        <div className="mt-2">
          Products: Refunds for products are available if returned within 30
          days of purchase and in original condition.{" "}
        </div>
        <div className="font-bold my-5">Refund Process</div>
        <div className="mt-2">
          Requesting a Refund: To request a refund, contact our customer service
          team with your order details.{" "}
        </div>
        <div className="mt-2">
          Refund Approval: Refunds will be processed upon receipt and inspection
          of the returned product or cancellation request.{" "}
        </div>
        <div className="mt-2">
          Refund Method: Refunds will be issued to the original payment method
          within 45 days of approval{" "}
        </div>
        <div className="font-bold my-5">Non-Refundable Items</div>
        <div className="mt-2">
          Customization Fees: Fees for customized products or services are
          non-refundable.{" "}
        </div>
        <div className="mt-2">
          Shipping Costs: Shipping costs are non-refundable unless the return is
          due to our error.{" "}
        </div>
        <div className="font-bold my-5">Contact Us</div>
        <div>
          For any questions or further clarifications, please feel free to reach
          out at our office at 17-Lawrence Road, Jinnah Town, Lahore.
          <div>
            Or contact us at our customer support team at{" "}
            <a
              href={`mailto:support@rhodium-tech.com`}
              className={"text-green-500 font-bold"}
            >
              support@rhodium-tech.com
            </a>{" "}
            or call us at{" "}
            <a
              href={`phone:+923357463486`}
              className={"text-green-500 font-bold"}
            >
              (+92) 335 746 3486
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
