import React from "react";
import "../Custom-CSS/Home.css";
import {
  GroupPhone,
  PlayStore,
  PhoneWithLocationMarker,
  Chart,
  ChargingCarImage,
  Journey,
  WorldMapPic,
  HeaderImage,
  Connector,
  HeaderSubImage1,
  HeaderSubImage2,
  ChargingIcon,
} from "../Features/Assets/images/imageImport";
import { Button, Card, SEO } from "../Features/UI";
import AppleSvg from "../Features/Assets/SvgIcons/AppleSvg";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const HandleOnClick1 = () => {
    navigate("/home-charging");
  };

  const HandleOnClick2 = () => {
    navigate("/charging-stations");
  };

  const HandleOnClick3 = () => {
    navigate("/ev-users");
  };

  const handleNavigation = () => {
    navigate("/ev-users");
  };

  return (
    <>
      {/* Header Start */}
      <SEO
        title="VerteCharge: Asia's Electric Charging Ecosystem"
        description="Introducing the first-of-its-kind SAAS platform that allows drivers to connect with smart chargers, locate chargers, make payments, and get live updates, all through the Verte app."
        name="VerteCharge"
        type="website"
        image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
        canonicalUrl={"https://vertecharge.com"}
      />
      <div className="mb-[5rem]">
        <div className="w-[90%]  grid lg:grid-cols-2 grid-cols-1 mt-12 mx-auto justify-evenly">
          <div className="flex items-center py-11 lg:mb-0 mb-10">
            <div>
              <div>
                <h3 className="text-headingColor font-bold xl:text-[4rem] xl:leading-[4.5rem] lg:text-[3.6rem] lg:leading-[4rem] text-[2.8rem] leading-[3.2rem] HeadingText">
                  Download{" "}
                  <span className="text-primaryColor italic">VERTE </span>
                  <br className="lg:block hidden" />
                  to <span className="text-primaryColor">electrify</span> your
                  journey
                </h3>
                <p className="text-textColor text-[1.125rem] my-6 ParaText">
                  Discover Asia's First AC and DC Fast charging
                  <br />
                  network with just the click of a button!
                </p>
              </div>
              <div className="flex gap-4 mt-11">
                <button className="px-2 py-1 bg-black flex items-center gap-1 rounded">
                  <img
                    className="h-8 media-img"
                    src={PlayStore}
                    alt="PlayStore"
                  />
                  <div className="text-white">
                    <p className="text-start leading-tight text-[0.78rem] BlackBtnSmallText">
                      GET IT ON
                      <br />
                      <span className="text-[1.25rem] font-semibold BlackBtnText">
                        Google Play
                      </span>
                    </p>
                  </div>
                </button>
                <button className="px-4 py-1 bg-black flex items-center gap-2 rounded">
                  <AppleSvg />
                  <div className="text-white">
                    <p className="text-start leading-tight text-[0.78rem] BlackBtnSmallText">
                      Download on the
                      <br />
                      <span className="text-[1.25rem] font-semibold BlackBtnText">
                        App Store
                      </span>
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="flex items-center lg:justify-end justify-center relative">
            <div className="H-img-div">
              <img
                className=" lg:max-w-[40rem] lg:max-h-[30rem] md:max-w-[25rem] md:max-h-[27rem] sm:max-w-[23rem] sm:max-h-[25rem] headerImage"
                src={HeaderImage}
                alt="HeaderImage"
              />
            </div>
            <div className="absolute xl:left-[5rem]  lg:left-[1rem] md:left-[8rem] md:top-[8rem] sm:top-[5rem] sm:left-[5rem] top-[10rem]  mediaControl-absolute-Himg1">
              <img
                className="sm:max-w-[13rem] label-img"
                src={HeaderSubImage1}
                alt="HeaderSubImage1"
              />
            </div>
            <div className="absolute xl:left-[20rem] lg:left-[15rem] md:left-[21rem] sm:left-[16rem] sm:bottom-6 bottom-10 mediaControl-absolute-Himg2">
              <img
                className="sm:max-w-[13rem] label-img"
                src={HeaderSubImage2}
                alt="HeaderSubImage2"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Building a world where electric mobility... */}
      <div className="flex justify-center mt-12 mb-3">
        <p
          className="text-center text-headingColor font-bold lg:text-[3rem] lg:leading-[3.5rem] md:text-[2.5rem] md:leading-[3rem]
        sm:text-[2rem] sm:leading-[2.5rem]  BuildingText"
        >
          Building a world where
          <br />
          electric mobility is the
          <span className="text-primaryColor"> new</span>
          <br />
          <span className="text-primaryColor">normal.</span>
        </p>
      </div>

      <div className="bg-sectionBgColor py-[2rem] my-[3rem]">
        <div className="w-[90%] mx-auto grid lg:grid-cols-2 grid-cols-1">
          <div className="flex items-center">
            <div>
              <h3 className="font-bold text-headingColor xl:text-[2.75rem] xl:leading-[3.3rem] lg:text-[2.4rem] lg:leading-[3rem] text-[2.4rem] leading-[3rem] DownloadVerteApp">
                Download the
                <span className="text-primaryColor italic"> VERTE APP</span>
                <br className="hidden lg:block" />
                <span className="ms-4 lg:ms-0">
                  for all your electric vehicle
                </span>
                <br className="hidden lg:block" />
                <span className="ms-1 lg:ms-0">charging needs</span>
              </h3>
              <p className="text-textColor py-4 font-normal lg:text-[1.25rem] lg:leading-tight text-[1rem] leading-tight subText">
                Discover Pakistan's first public charging network with just the
                click of a button. When ever you are out on the road, Verte
                ensures that your charging session is quick and easy.
              </p>
              <Button
                onClick={handleNavigation}
                title="Learn More"
                bgColor="bg-primaryColor"
                textColor="text-white"
              />
              <div className="flex gap-4 mt-11">
                <button className="px-2 py-1 bg-black flex items-center gap-1 rounded">
                  <img
                    className="h-8 media-img"
                    src={PlayStore}
                    alt="PlayStore"
                  />
                  <div className="text-white">
                    <p className="text-start leading-tight text-[0.78rem] BlackBtnSmallText">
                      GET IT ON
                      <br />
                      <span className="text-[1.25rem] font-semibold BlackBtnText">
                        Google Play
                      </span>
                    </p>
                  </div>
                </button>
                <button className="px-4 py-1 bg-black flex items-center gap-2 rounded">
                  <AppleSvg />
                  <div className="text-white">
                    <p className="text-start leading-tight text-[0.78rem] BlackBtnSmallText">
                      Download on the
                      <br />
                      <span className="text-[1.25rem] font-semibold BlackBtnText">
                        App Store
                      </span>
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="flex lg:justify-end justify-center lg:my-0 my-12 ">
            <img className="max-h-[30rem]" src={GroupPhone} alt="GroupPhone" />
          </div>
        </div>
      </div>

      {/* Chart section */}
      <div className="flex items-center justify-center">
        <div>
          <p
            className="text-center font-bold text-[3rem] mt-8 mb-12 lg:text-[3rem] lg:leading-[3.5rem] md:text-[2.5rem] md:leading-[3rem]
          sm:text-[2rem] sm:leading-[2.5rem]  BuildingText"
          >
            Do you want to be an EV
            <br />
            Charging Station <span className="text-primaryColor">Owner</span> or
            <br />
            <span className="text-primaryColor">Operator?</span>
          </p>
          <div className=" px-11 sm:p-0">
            <img className="" src={Chart} alt="Chart" />
          </div>
          <p className="text-center font-semibold md:text-2xl leading-tight sm:text-xl text-lg subText my-10">
            Unlock the true potential of your charging
            <br />
            infrastructure by integrating Verte's SaaS
            <br /> Platform
          </p>
          <div className="flex flex-wrap gap-7 justify-center mb-10">
            <button className="px-2 py-1 order-1 media-order-google bg-black flex items-center gap-1 rounded">
              <img className="sm:h-8 h-7" src={PlayStore} alt="PlayStore" />
              <div className="text-white">
                <p className="text-start leading-tight text-[0.78rem] ">
                  GET IT ON
                  <br />
                  <span className="text-[1.25rem] font-semibold ">
                    Google Play
                  </span>
                </p>
              </div>
            </button>

            <button
              onClick={() => {
                navigate("/station-owners");
              }}
              className="font-bold order-2 media-order-learn bg-primaryColor px-10 text-white rounded LearnMoreBtn"
            >
              Learn More
            </button>

            <button className="px-4 py-1 order-3 media-order-download bg-black flex items-center gap-2 rounded">
              <AppleSvg />
              <div className="text-white">
                <p className="text-start leading-tight text-[0.78rem] ">
                  Download on the
                  <br />
                  <span className="text-[1.25rem] font-semibold ">
                    App Store
                  </span>
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* Card Section */}
      <Card
        bgColor="bg-sectionBgColor"
        Title1Text={"Home Charging"}
        Title2Text={"Public Charging"}
        Title3Text={"VERTE Charge Mobile App"}
        Para1Text={
          "Make your home charging experience smart and secure with our AC home charger"
        }
        Para2Text={
          "Access the biggest integrated public charging network to make booking and payments stress free"
        }
        Para3Text={
          "Get an all in one home and public charging solution with the verte app. Download now!"
        }
        btnText1={"Learn More"}
        btnText2={"Learn More"}
        btnText3={"Learn More"}
        onClick1={HandleOnClick1}
        onClick2={HandleOnClick2}
        onClick3={HandleOnClick3}
      />

      {/* Download Verte Home app */}
      <div className="grid lg:grid-cols-2 grid-cols-1 my-12">
        <div className="w-[90%] mx-auto lg:flex lg:items-center lg:justify-end">
          <div>
            <p className="font-bold sm:text-[2rem] text-[28px] sm:leading-10 leading-9 MarkerHeaderText">
              Download the{" "}
              <span className="text-primaryColor">
                Verte
                <br className="lg:block md:hidden " />
                Charge
              </span>{" "}
              your mobility companion
            </p>
            <img
              className="md:w-[30rem] w-[25rem] mt-5 lg:block hidden"
              src={ChargingCarImage}
              alt="ChargingCarImage"
            />
          </div>
        </div>
        <div>
          <div className="flex justify-center items-center lg:mt-0 mt-10">
            <img
              className="sm:w-[28rem] w-[25rem] md:px-0 px-11"
              src={PhoneWithLocationMarker}
              alt="PhoneWithLocationMarker"
            />
          </div>
        </div>
      </div>

      {/* Want to be a veret franchisee */}
      <div
        className="bg-cover bg-bottom w-full bg-no-repeat flex items-center max-w-full h-auto justify-center "
        style={{ backgroundImage: `url(${Journey})` }}
      >
        <div className="lg:mt-[8rem] lg:mb-[12rem] md:mt-[7rem] md:mb-[11rem] sm:mt-[6rem] sm:mb-[8rem] mt-[4rem] mb-[6rem]">
          <p
            className="text-white text-center font-bold lg:text-[3rem] lg:leading-[3.4rem] md:text-[2.5rem] md:leading-[3rem]
          sm:text-[2rem] sm:leading-[2.2rem] text-[1.75rem] leading-[2rem] bg-img-text
          "
          >
            Want to be a Verte Franchisee?
            <br />
            Reach out to us:
          </p>
          <div className="flex justify-center">
            <button
              onClick={() => {
                navigate("/lets-talk");
              }}
              className="text-white bg-primaryColor rounded py-2 px-10 mt-7 btnLetsTalk"
            >
              Lets Talk?
            </button>
          </div>
        </div>
      </div>

      {/* connector section */}
      <div className="relative">
        <div className="w-[90%] bg-white mx-auto rounded-[2.5rem] -mt-[4.5rem]">
          <div className="md:px-[7rem] md:pt-[3rem] pb-[3rem]">
            <div className="md:pt-0 pt-[10rem]">
              <div className="flex justify-center -mt-[5rem] md:hidden absolute top-[5rem] right-0">
                <img className="w-[13rem]" src={Connector} alt="Connector" />
              </div>
              <p className="font-bold md:text-3xl sm:text-2xl text-xl text-center md:text-start">
                Customized Charging
                <br />
                Solutions for all type of
                <br />
                properties
              </p>
              <div className="flex flex-col  sm:flex-row flex-wrap my-12 sm:justify-between gap-3 sm:gap-0">
                <div className="flex items-center  gap-2">
                  <div className=" bg-sectionBgColor w-10 h-10 rounded-full flex items-center justify-center">
                    <img
                      className="h-5"
                      src={ChargingIcon}
                      alt="ChargingIcon"
                    />
                  </div>
                  <h3>Fuel Station</h3>
                </div>
                <div className="flex items-center gap-2 md:mx-auto">
                  <div className=" bg-sectionBgColor w-10 h-10 rounded-full flex items-center justify-center">
                    <img
                      className="h-5"
                      src={ChargingIcon}
                      alt="ChargingIcon"
                    />
                  </div>
                  <h3>Warehouse</h3>
                </div>
                <div className="flex items-center gap-2">
                  <div className=" bg-sectionBgColor w-10 h-10 rounded-full flex items-center justify-center">
                    <img
                      className="h-5"
                      src={ChargingIcon}
                      alt="ChargingIcon"
                    />
                  </div>
                  <h3>
                    Commercial
                    <br className="hidden sm:block" />
                    <span className="ms-1 sm:ms-0">Building</span>
                  </h3>
                </div>
              </div>
              <div className="-mt-[2.25rem] sm:mt-0 flex flex-wrap sm:flex-row flex-col gap-3 sm:gap-0  sm:justify-evenly">
                <div className="flex items-center gap-2">
                  <div className=" bg-sectionBgColor w-10 h-10 rounded-full flex items-center justify-center">
                    <img
                      className="h-5"
                      src={ChargingIcon}
                      alt="ChargingIcon"
                    />
                  </div>
                  <h3>
                    Service
                    <br className="hidden sm:block" />
                    <span className="ms-1 sm:ms-0">Apartments</span>
                  </h3>
                </div>
                <div className="flex items-center gap-2">
                  <div className=" bg-sectionBgColor w-10 h-10 rounded-full flex items-center justify-center">
                    <img
                      className="h-5"
                      src={ChargingIcon}
                      alt="ChargingIcon"
                    />
                  </div>
                  <h3>Industrial Building</h3>
                </div>
              </div>
            </div>
            <div className="absolute top-0 right-12 sm:block hidden">
              <img className="w-full" src={WorldMapPic} alt="WorldMapPic" />
            </div>
          </div>
        </div>
        <div className=" absolute top-8 right-0 md:block hidden">
          <img
            className="w-[15rem] h-[15rem]"
            src={Connector}
            alt="Connector"
          />
        </div>
      </div>
      <div className="mt-[5rem]" />
    </>
  );
}

export default Home;
