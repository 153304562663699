import React from "react";
import "../Custom-CSS/EvUsers.css";
// import { Button, SEO } from "../Features/UI";
import { SEO } from "../Features/UI";
import {
  Car,
  ChargingIcon,
  Phone,
  Phone2,
  PhoneWithLocationMarker,
  PlayStore,
  WorldMapPic,
} from "../Features/Assets/images/imageImport";
import AppleSvg from "../Features/Assets/SvgIcons/AppleSvg";
import { useNavigate } from "react-router-dom";

function EvUsers() {
  const navigate = useNavigate();
  const HandleOnClick = () => {
    navigate("/lets-talk");
  };
  return (
    <div>
      {/* <CustomNavbar /> */}

      {/* Header Section */}
      <SEO
        title="VerteCharge: EV Owners and Drivers"
        description="Discover Asia's First AC and DC Fast Charging network with
        just the click of a button"
        name="VerteCharge"
        type="article"
        image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
        canonicalUrl={"https://vertecharge.com/ev-users"}
      />
      <div className="my-[2.5rem]">
        <div className="w-[90%] mx-auto grid lg:grid-cols-2 grid-cols-1">
          <div className="flex justify-start mt-[3.5rem]">
            <div>
              <h1 className="text-[4rem] leading-[5rem] font-bold media-heading-size">
                Your on Road{" "}
                <span className="text-primaryColor">
                  EV
                  <br className="lg:block hidden" /> Assistant
                </span>
              </h1>
              <p className="font-normal text-textColor text-[1.125rem] py-5 media-text-size">
                Discover Asia's First AC and DC Fast Charging network with just
                the click of a button
              </p>
              <div className="flex gap-4">
                <button className="px-2 py-1 bg-black flex items-center gap-1 rounded">
                  <img
                    className="h-8 media-img"
                    src={PlayStore}
                    alt="PlayStore"
                  />
                  <div className="text-white">
                    <p className="text-start leading-tight text-[0.78rem] BlackBtnSmallText">
                      GET IT ON
                      <br />
                      <span className="text-[1.25rem] font-normal BlackBtnText">
                        Google Play
                      </span>
                    </p>
                  </div>
                </button>
                <button className="px-4 py-1 bg-black flex items-center gap-2 rounded">
                  <AppleSvg />
                  <div className="text-white">
                    <p className="text-start leading-tight text-[0.78rem] BlackBtnSmallText">
                      Download on the
                      <br />
                      <span className="text-[1.25rem] font-normal BlackBtnText">
                        App Store
                      </span>
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="flex lg:justify-end justify-center lg:mt-0 mt-[5rem] items-center">
            <img
              className="sm:w-[28rem]  w-[23rem]"
              src={PhoneWithLocationMarker}
              alt="PhoneWithLocationMarker"
            />
          </div>
        </div>
      </div>

      {/* Features of Verte Charge App */}
      <div className="relative overflow-hidden my-[2.5rem]">
        <div className="w-[90%] relative mx-auto ">
          <div className="px-5 pt-[3rem] pb-[4rem] ">
            <div>
              <div className="flex md:justify-start justify-center">
                <p className="font-bold text-[2.5rem] leading-[3rem] media-textSize">
                  <p className="text-xl text-center md:text-start media-functional">
                    Functional
                  </p>
                  <h1 className="text-center md:text-start">
                    Features of
                    <br />
                    <span className="text-primaryColor"> VERTE CHARGE APP</span>
                  </h1>
                </p>
              </div>
              <div className="flex flex-col  sm:flex-row flex-wrap my-12 sm:justify-between gap-3 sm:gap-0">
                <div className="flex items-center  gap-2">
                  <div className=" bg-sectionBgColor w-12 h-12 rounded-full flex items-center justify-center">
                    <img
                      className="h-5"
                      src={ChargingIcon}
                      alt="ChargingIcon"
                    />
                  </div>
                  <h3 className="text-[1.125rem] font-normal">Fuel Station</h3>
                </div>
                <div className="flex items-center gap-2 md:mx-auto">
                  <div className=" bg-sectionBgColor w-12 h-12 rounded-full flex items-center justify-center">
                    <img
                      className="h-5"
                      src={ChargingIcon}
                      alt="ChargingIcon"
                    />
                  </div>
                  <h3 className="text-[1.125rem] font-normal">Warehouse</h3>
                </div>
                <div className="flex items-center gap-2">
                  <div className=" bg-sectionBgColor w-12 h-12 rounded-full flex items-center justify-center">
                    <img
                      className="h-5"
                      src={ChargingIcon}
                      alt="ChargingIcon"
                    />
                  </div>
                  <h3 className="text-[1.125rem] font-normal">
                    Commercial
                    <br className="hidden sm:block" />
                    <span className="ms-1 sm:ms-0">Building</span>
                  </h3>
                </div>
              </div>
              <div className="-mt-[2.25rem] sm:mt-0 flex flex-wrap sm:flex-row flex-col gap-3 sm:gap-0  sm:justify-evenly">
                <div className="flex items-center gap-2">
                  <div className=" bg-sectionBgColor w-12 h-12 rounded-full flex items-center justify-center">
                    <img
                      className="h-5"
                      src={ChargingIcon}
                      alt="ChargingIcon"
                    />
                  </div>
                  <h3 className="text-[1.125rem] font-normal">
                    Service
                    <br className="hidden sm:block" />
                    <span className="ms-1 sm:ms-0">Apartments</span>
                  </h3>
                </div>
                <div className="flex items-center gap-2">
                  <div className=" bg-sectionBgColor w-12 h-12 rounded-full flex items-center justify-center">
                    <img
                      className="h-5"
                      src={ChargingIcon}
                      alt="ChargingIcon"
                    />
                  </div>
                  <h3 className="text-[1.125rem] font-normal">
                    Industrial Building
                  </h3>
                </div>
              </div>
            </div>
            <div className="absolute top-0 right-12 md:block hidden">
              <img
                className="w-full h-50rem"
                src={WorldMapPic}
                alt="WorldMapPic"
              />
            </div>
            <div className="absolute top-3 right-0 md:block hidden">
              <img className="w-[23rem]" src={Car} alt="Car" />
            </div>
            <div className="flex justify-center mt-[5rem]">
              <button
                onClick={HandleOnClick}
                className="bg-primaryColor text-white py-4 px-12 z-30 rounded font-bold"
              >
                Contact For Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Vehicle Data Summary On Verte app */}

      <div className="my-[2.5rem]">
        <div className="w-[75%] mx-auto grid lg:grid-cols-2 grid-cols-1">
          <div className="flex justify-start items-center">
            <div>
              <h1 className="font-bold text-[2.5rem] leading-[3rem]">
                Vehicle Data Summary on{" "}
                <span className="text-primaryColor">Verte App</span>
              </h1>
              <p className="text-textColor text-[1.125rem] py-5">
                With the Verte App, you have access to a wealth of information
                about your electric vehicle (EV) at your fingertips. Our vehicle
                data summary feature provides you with a comprehensive overview
                of your EV's performance and key metrics.
              </p>
              {/* <Button
                title="Learn More"
                bgColor="bg-primaryColor"
                textColor="text-white"
              /> */}
            </div>
          </div>
          <div className="flex lg:justify-end justify-center mt-10 lg:mt-0 items-center">
            <img className="w-[20rem]" src={Phone} alt="phone" />
          </div>
        </div>
      </div>

      {/* Vehicle Data Summary On Verte app */}
      <div className="mt-[2.5rem] mb-[4rem]">
        <div className="w-[75%] mx-auto grid lg:grid-cols-2 grid-cols-1">
          <div className="flex justify-center items-center lg:order-2">
            <div>
              <h1 className="font-bold text-[2.5rem] leading-[3rem]">
                Book My{" "}
                <span className="text-primaryColor">Charging Session</span>
              </h1>
              <p className="text-textColor text-[1.125rem] py-5">
                Embark on your electric vehicle (EV) journey with the utmost
                convenience and confidence through our cutting-edge "Book My
                Charging Session" feature on the Verte App. Designed to cater to
                both seasoned EV enthusiasts and newcomers to the world of
                electric mobility, this feature offers an unparalleled
                experience that goes beyond conventional trip planning.
              </p>
              {/* <Button
                title="Learn More"
                bgColor="bg-primaryColor"
                textColor="text-white"
              /> */}
            </div>
          </div>
          <div className="flex lg:justify-start justify-center items-center lg:order-1 mt-10 lg:mt-0">
            <img className="w-[20rem]" src={Phone2} alt="phone" />
          </div>
        </div>
      </div>
      {/* Footer */}
      {/* <Footer /> */}
    </div>
  );
}

export default EvUsers;
