import React from "react";

function ToiletGenderSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 51 51"
      fill="none"
    >
      <path
        d="M11.4901 12.4146C13.4434 12.4146 15.0275 10.831 15.0275 8.87842C15.0275 6.93611 13.4434 5.35254 11.4901 5.35254C9.54705 5.35254 7.96289 6.93611 7.96289 8.87842C7.96289 10.831 9.54705 12.4146 11.4901 12.4146Z"
        fill="#34B44A"
      />
      <path
        d="M5.65718 27.6003C6.20575 27.6003 6.66202 27.1442 6.66202 26.5958V18.0886C6.66202 17.8631 6.84146 17.6786 7.07216 17.6786C7.30799 17.6786 7.49768 17.8682 7.49768 18.0886V44.5481C7.49768 45.5423 8.30258 46.3418 9.28179 46.3418C10.2764 46.3418 11.0761 45.5372 11.0761 44.5481V29.1172C11.0761 28.8815 11.2658 28.6919 11.4863 28.6919C11.7221 28.6919 11.9118 28.8815 11.9118 29.1172V44.5481C11.9118 45.5423 12.7167 46.3418 13.7062 46.3418C14.6854 46.3418 15.4903 45.5372 15.4903 44.5481V18.0937C15.4903 17.8682 15.68 17.6837 15.9158 17.6837C16.1516 17.6837 16.3259 17.8733 16.3259 18.0937V26.6009C16.3259 27.1493 16.7822 27.6054 17.3308 27.6054C17.8896 27.6054 18.3356 27.1493 18.3356 26.6009V17.3865C18.3356 15.1008 16.4849 13.2507 14.2086 13.2507H8.78962C6.5031 13.2507 4.65234 15.1008 4.65234 17.3865V26.5958C4.65234 27.1442 5.09837 27.6003 5.65718 27.6003Z"
        fill="#34B44A"
      />
      <path
        d="M36.9563 45.8329C37.2895 46.1557 37.7458 46.3556 38.2175 46.3556C39.212 46.3556 40.0118 45.5407 40.0118 44.5517V35.4397H36.418V44.5517C36.418 45.0283 36.6077 45.4741 36.9563 45.8329Z"
        fill="#34B44A"
      />
      <path
        d="M32.5268 45.8329C32.8601 46.166 33.3061 46.3556 33.788 46.3556C34.7826 46.3556 35.5824 45.5407 35.5824 44.5517V35.4397H32.0039V44.5517C32.0039 45.0283 32.1833 45.4844 32.5268 45.8329Z"
        fill="#34B44A"
      />
      <path
        d="M35.9876 12.4146H36.0235C37.9666 12.4043 39.5353 10.8207 39.5353 8.87842C39.5353 6.93611 37.9512 5.35254 36.0082 5.35254C34.0549 5.35254 32.4707 6.93611 32.4707 8.87842C32.4707 10.8207 34.0446 12.4043 35.9876 12.4146Z"
        fill="#34B44A"
      />
      <path
        d="M27.0161 26.6009C27.2622 26.6881 27.5288 26.6778 27.7748 26.5548C28.0209 26.4421 28.2004 26.2422 28.2875 25.9962C28.5797 25.1352 29.0924 23.7566 29.6051 22.3627C30.2767 20.569 31.1021 18.3243 31.1431 18.0476C31.1534 17.981 31.1892 17.9246 31.22 17.8785C31.3072 17.735 31.4763 17.6427 31.6455 17.6786C31.8814 17.7247 32.0352 17.9348 31.989 18.1603L29.236 34.6059H31.5584C31.5686 34.6059 31.5686 34.5957 31.5789 34.5957C31.5891 34.5957 31.5891 34.6059 31.5994 34.6059H35.9725C35.9827 34.6059 35.9827 34.5957 35.993 34.5957C36.0033 34.5957 36.0033 34.6059 36.0135 34.6059H40.3969C40.4071 34.6059 40.4071 34.5957 40.4174 34.5957C40.4276 34.5957 40.4276 34.6059 40.4379 34.6059H42.7603L40.0072 18.1603C39.9714 17.94 40.12 17.7247 40.3507 17.6786C40.4943 17.6581 40.643 17.7247 40.7404 17.8323L40.7506 17.8426C40.7609 17.8631 40.7711 17.8785 40.7865 17.899C40.7968 17.9195 40.8224 17.9451 40.8326 17.9758L43.7087 25.9808C43.7959 26.2371 43.9856 26.4369 44.2317 26.5497C44.4675 26.6727 44.7341 26.6829 44.9802 26.5958C45.3801 26.4523 45.662 26.0731 45.662 25.6477C45.662 25.5452 45.6415 25.4222 45.5954 25.3146C44.4778 21.1327 42.9756 16.2385 42.0374 14.9471C41.8477 14.6908 41.6375 14.4653 41.6273 14.4653C40.5097 13.3379 39.1152 13.2507 38.7153 13.2507H36.0033C35.993 13.2507 35.993 13.2507 35.9828 13.2507H33.2963C32.8965 13.2507 31.502 13.3379 30.3741 14.4653C30.3638 14.4653 30.1485 14.6908 29.964 14.9471C29.036 16.2283 27.5236 21.1225 26.4163 25.2838C26.3701 25.4273 26.3496 25.5503 26.3496 25.6528C26.3496 26.0731 26.6162 26.4523 27.0161 26.6009Z"
        fill="#34B44A"
      />
      <path
        d="M22.6367 46.3568C22.9442 46.3568 23.1953 46.1211 23.1953 45.8239V5.88088C23.1953 5.58877 22.9442 5.3479 22.6367 5.3479C22.3292 5.3479 22.0781 5.58364 22.0781 5.88088V45.8239C22.0832 46.116 22.3292 46.3568 22.6367 46.3568Z"
        fill="#34B44A"
      />
    </svg>
  );
}

export default ToiletGenderSvg;
