import React, { useEffect, useState } from "react";
import {
  Connector,
  HeaderImage2,
  TabletPhone,
  HardwareImage,
  SoftwareImage,
} from "../Features/Assets/images/imageImport";
import { BagSvg, PartnershipSvg, PhoneSvg } from "../Features/Assets/SvgIcons";
import PlusSvg from "../Features/Assets/SvgIcons/PlusSvg";
import { SEO } from "../Features/UI";
import { useNavigate } from "react-router-dom";
const images = [SoftwareImage, HardwareImage, TabletPhone];

function EvUsers2() {
  const [showHardware, setShowHardware] = useState(false);
  const [showSoftware, setShowSoftware] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [currentImage, setCurrentImage] = useState(images[2]);

  useEffect(() => {
    setShowSoftware(true);
    setCurrentImage(images[2]);
  }, []);

  const HardwareAccordian = () => {
    setShowHardware(!showHardware);
    setShowSoftware(false);
    setShowServices(false);
    setCurrentImage(images[1]);
  };
  const SoftwareAccordian = () => {
    setShowSoftware(!showSoftware);
    setShowHardware(false);
    setShowServices(false);
    setCurrentImage(images[2]);
  };
  const ServiceAccordian = () => {
    setShowServices(!showServices);
    setShowHardware(false);
    setShowSoftware(false);
    setCurrentImage(images[0]);
  };

  const navigate = useNavigate();

  const HandleOnClick = () => {
    navigate("/lets-talk");
  };

  return (
    <div>
      {/* <Header start /> */}
      <SEO
        title="VerteCharge: Station Owners and Operators"
        description="For all of your charging needs, with our turn-key approach, we simplify the entire process, making
        it easier than ever to deploy EV charging stations in your area."
        name="VerteCharge"
        type="article"
        image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
        canonicalUrl={"https://vertecharge.com/station-owners"}
      />
      <div className="w-[90%] mx-auto grid lg:grid-cols-2 grid-cols-1 my-[2.5rem]">
        <div className="flex flex-col items-start lg:items-center py-11 lg:mb-0 mb-10">
          <h1 className="font-bold lg:text-[3.2rem] lg:leading-[4.3rem] sm:text-[2.8rem] sm:leading-[3.5rem] text-[2rem] leading-[2.5rem]">
            Do you want to be an Ev Charging Station{" "}
            <span className="text-primaryColor">Owner</span> or{" "}
            <span className="text-primaryColor">Operator</span>?
          </h1>
          <p className="text-textColor mt-3 text-[1.25rem]">
            Discover Asia's First AC and DC Fast charging network with just the
            click of a button
          </p>
        </div>
        <div className="flex lg:justify-end justify-center lg:mt-0 mt-10">
          <img className="max-h-[32rem]" src={HeaderImage2} alt="HeaderImage" />
        </div>
      </div>

      {/* One Partner */}
      <div className="w-[90%] mx-auto mt-[5rem]">
        <div>
          <h1 className=" sm:text-[2.5rem] text-[2rem] text-headingColor sm:leading-[2.8rem] leading-[2.5rem] font-bold">
            One Partner
          </h1>
          <h3 className=" sm:text-[2.5rem] text-[2rem] text-headingColor sm:leading-[2.8rem] leading-[2.5rem] font-bold">
            For all of your charging needs
          </h3>
          <p className="text-textColor text-[1.25rem] mt-3 mb-10">
            With our turn-key approach, we simplify the entire process, making
            it easier than ever to deploy EV charging stations in your area.
          </p>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-12">
          <div className="lg:order-1 order-2 flex  lg:justify-start justify-center">
            <img
              className="max-h-[33rem]"
              src={currentImage}
              alt="TabletPhone"
            />
          </div>
          <div className="lg:order-2 order-1 lg:mb-0 mb-[3.5rem]">
            <div className="flex flex-col gap-10">
              <div>
                <div
                  onClick={SoftwareAccordian}
                  className="flex cursor-pointer items-center justify-between border-b-2 border-borderColor pb-3"
                >
                  <h1 className="text-4xl font-bold">Software</h1>
                  <PlusSvg />
                </div>
                {showSoftware && (
                  <p className="text-textColor text-[1.25rem] mt-5">
                    Get complete control and key insights with Verte’s advanced
                    EV charging management software, which works seamlessly with
                    the Verte driver network.
                  </p>
                )}
              </div>

              <div>
                <div
                  onClick={HardwareAccordian}
                  className="flex items-center cursor-pointer justify-between border-b-2 border-borderColor pb-3"
                >
                  <h1 className="text-4xl font-bold">Hardware</h1>
                  <PlusSvg />
                </div>
                {showHardware && (
                  <p className="text-textColor text-[1.25rem] mt-5">
                    Verte Partners with trusted global EVSE suppliers to offer a
                    portfolio of networked AC and DC charging stations, designed
                    using cutting-edge hardware technology to be safe and
                    reliable while offering drivers a user-friendly, premium
                    charging experience.
                  </p>
                )}
              </div>

              <div>
                <div
                  onClick={ServiceAccordian}
                  className="flex cursor-pointer items-center justify-between border-b-2 border-borderColor pb-3"
                >
                  <h1 className="text-4xl font-bold">Services</h1>
                  <PlusSvg />
                </div>
                {showServices && (
                  <p className="text-textColor text-[1.25rem] mt-5">
                    Verte Charge offers a suite of best-in-class services,
                    including flexible financing, site selection consulting,
                    dedicated help with implementation and industry-leading
                    support.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Why you should be a Charge Point Operator */}
      <div className="flex justify-center mt-[5rem]">
        <div>
          <h1 className="font-bold sm:text-[2.5rem] text-[2rem] text-headingColor sm:leading-[2.8rem] leading-[2.5rem] text-center">
            Why you should be a{" "}
            <span className="text-primaryColor">
              {" "}
              Charge <br className="sm:block hidden" /> Point
            </span>{" "}
            Operator?
          </h1>
          <p className="w-[80%] mx-auto text-center my-10 text-[1.25rem] text-headingColor">
            Becoming a Charge Point Operator (CPO) provider offers businesses a
            unique opportunity to capitalize on the upcoming transformation
            towards electric mobility, positioning them at the forefront of this
            revolutionary shift set to unfold over the next decade. Embracing
            electric vehicles is the key to the future of mobility.
          </p>
          <div className="w-[90%] mx-auto">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 border border-borderColor rounded p-10 gap-10">
              <div className="flex flex-col items-center">
                <BagSvg />
                <h3 className="text-primaryColor font-medium text-2xl my-5">
                  Manage Your Asset
                </h3>
                <p className="text-[1.25rem] text-headingColor text-center">
                  Choose a partnership model and contract structure that best
                  suits your business model
                </p>
              </div>

              <div className="flex flex-col items-center">
                <BagSvg />
                <h3 className="text-primaryColor font-medium text-2xl my-5">
                  Generate Revenue
                </h3>
                <p className="text-[1.25rem] text-headingColor text-center">
                  Choose a partnership model and contract structure that best
                  suits your business model
                </p>
              </div>

              <div className="flex flex-col items-center md:col-span-2 lg:col-span-1">
                <BagSvg />
                <h3 className="text-primaryColor font-medium text-2xl my-5 text-center">
                  Attain High Quality Rates
                </h3>
                <p className="text-[1.25rem] text-headingColor text-center  md:w-1/2 lg:w-full">
                  Choose a partnership model and contract structure that best
                  suits your business model
                </p>
              </div>
            </div>
            <div className="flex justify-center mt-[3.5rem] mb-[6rem]">
              <button
                onClick={HandleOnClick}
                className="bg-primaryColor text-white py-4 px-12 rounded font-bold text-[1.125rem]"
              >
                Contact For Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* 3 Easy Step section */}
      <div className=" bg-primaryColor relative">
        <div className="flex justify-end md:hidden relative top-6">
          <img
            className="max-h-[15rem] max-w-[15rem]"
            src={Connector}
            alt="Connector"
          />
        </div>
        <div className="w-[90%] mx-auto pt-[4rem] pb-[3rem]">
          <p className="font-bold text-white sm:text-[2.5rem] sm:leading-[2.8rem] text-[2rem] leading-[2.5rem] text-center md:text-start ">
            <span className="text-headingColor">3 Easy Step </span> We Will{" "}
            <br className="md:block hidden" />
            TakeCare of the Rest
          </p>
          <div className="grid lg:grid-cols-3 md:grid-cols-2  mt-10">
            <div className="flex px-5 flex-col items-center">
              <div className="h-[3.5rem] w-[3.5rem] flex justify-center items-center bg-white rounded-full">
                <PhoneSvg />
              </div>
              <div className="flex flex-col items-center mt-3">
                <h1 className="text-white font-bold text-2xl">
                  Talk to Verte EV Experts
                </h1>
                <p className="text-white mt-2 text-[1.25rem] text-center">
                  Verte team can help design a plan for your entry int the EV
                  market and the pat to follow for scalability
                </p>
              </div>
            </div>

            <div className="flex px-5 md:mt-0 mt-10 flex-col items-center">
              <div className="h-[3.5rem] w-[3.5rem] flex justify-center items-center bg-white rounded-full">
                <PhoneSvg />
              </div>
              <div className="flex flex-col items-center mt-3">
                <h1 className="text-white font-bold text-2xl">
                  Arrange factory visit
                </h1>
                <p className="text-white mt-2 text-[1.25rem] text-center">
                  Schedule a free site visit to determine suitable integrations
                </p>
              </div>
            </div>

            <div className="flex px-5 md:mt-0 mt-10 md:col-span-2 lg:col-span-1 flex-col items-center">
              <div className="h-[3.5rem] w-[3.5rem] flex justify-center items-center bg-white rounded-full">
                <PartnershipSvg />
              </div>
              <div className="flex flex-col items-center mt-3">
                <h1 className="text-white font-bold text-2xl">
                  Select Partnership Model
                </h1>
                <p className="text-white mt-2 text-[1.25rem] text-center md:w-1/2 lg:w-full">
                  Choose a partnership model and contract structure that best
                  suits your business model
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-[5rem]">
            <button
              onClick={HandleOnClick}
              className="bg-white text-primaryColor py-4 px-12 text-[1.125rem] rounded font-bold"
            >
              Contact For Free Trial
            </button>
          </div>
        </div>
        <div className="absolute top-0 right-0 hidden md:block">
          <img
            className="h-[15rem] w-[15rem]"
            src={Connector}
            alt="Connector"
          />
        </div>
      </div>
    </div>
  );
}

export default EvUsers2;
