import React from "react";

function Button({title, bgColor, textColor, onClick}) {
  const buttonStyle = `items-center ${bgColor} font-bold  border-1 py-[10px] px-[20px] focus:outline-none ${textColor} rounded-[10px] sm:text-base text-[14px] mt-4 md:mt-0`;
  return (
    <button onClick={onClick} className={buttonStyle}>
      {title}
    </button>
  );
}

export default Button;
