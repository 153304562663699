import React from "react";

function AppleSvg({ width, height }) {
  return (
    <svg
      className="Icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "30"}
      height={height || "32"}
      viewBox="0 0 24 26"
      fill="none"
    >
      <path
        d="M16.3359 4.14672C16.8931 3.58032 17.3179 2.92243 17.586 2.21081C17.8541 1.49919 17.9602 0.747876 17.8982 0C16.1876 0.121785 14.5997 0.831368 13.4766 1.97587C12.9388 2.5244 12.5312 3.16253 12.2781 3.85248C12.0249 4.54243 11.9313 5.27017 12.0027 5.9926C12.8372 5.99873 13.662 5.83544 14.4134 5.51535C15.1648 5.19526 15.8226 4.72697 16.3359 4.14672ZM20.05 13.8181C20.0599 12.822 20.3574 11.8446 20.9144 10.978C21.4714 10.1115 22.2696 9.38441 23.2336 8.86541C22.6252 8.09227 21.821 7.45479 20.8846 7.00351C19.9483 6.55223 18.9056 6.29958 17.8392 6.26558C15.54 6.0576 13.4176 7.44851 12.1943 7.44851C10.971 7.44851 9.24661 6.29158 7.33058 6.31758C6.07798 6.35397 4.85746 6.67607 3.78807 7.25246C2.71867 7.82885 1.8369 8.63985 1.22878 9.60636C-1.36523 13.5841 0.565538 19.4987 3.15954 22.7095C4.33863 24.2824 5.8125 26.0633 7.758 25.9983C9.7035 25.9333 10.3373 24.9323 12.5923 24.9323C14.8473 24.9323 15.54 25.9983 17.456 25.9593C19.3721 25.9203 20.728 24.3474 21.9661 22.7745C22.8431 21.6325 23.5282 20.3847 24 19.0697C22.8319 18.6304 21.8354 17.8989 21.1334 16.9656C20.4314 16.0323 20.0548 14.9381 20.05 13.8181Z"
        fill="white"
      />
    </svg>
  );
}

export default AppleSvg;
