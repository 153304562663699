import React from "react";
import { SEO } from "../Features/UI";
import { campaign, lock } from "../Features/Assets/images/imageImport";
import "../Custom-CSS/FleetManagement.css";
import { useNavigate } from "react-router-dom";
import { LaptopSvg, GraphSvg, DashboardSvg } from "../Features/Assets/SvgIcons";
const FleetManagement = () => {
  const navigate = useNavigate();
  const handleOnPress = () => {
    navigate("/lets-talk");
  };
  return (
    <>
      <div className="overflow-x-hidden">
        <SEO
          title="VerteCharge: Fleet Management"
          description="Streamlining efficiency and optimizing performance for electric vehicle fleets with cutting-edge management solutions."
          name="VerteCharge"
          type="article"
          image={process.env.PUBLIC_URL + "/images/link-image.jpg"}
          canonicalUrl={"https://vertecharge.com/fleet-management"}
        />
        <div className="w-[100%] lg:mx-[60px] lg:h-screen h-auto flex flex-col lg:flex-row md:flex-col lg:items-center">
          <div className="lg:min-w-0 media-textAlignment3">
            <h1 className="lg:w-[550px] w-[100%] lg:text-[4rem] font-bold media-heading-size">
              <span className="text-neutral-900 lg:text-[64px] font-bold md:text-[60px] sm:text-[52px] leading-tight">
                Elevate Your{" "}
              </span>
              <span className="text-primaryColor lg:text-[64px] font-bold md:text-[60px] sm:text-[52px] leading-tight">
                Fleet Management{" "}
              </span>
              <span className="text-neutral-900 lg:text-[64px] font-bold md:text-[60px] sm:text-[52px] leading-tight">
                with Verte Charge
              </span>
            </h1>
            <p className="pb-[25px] pt-[20px] lg:w-[409px] w-[100%] ">
              <span className="text-neutral-900 lg:text-[24px] md:text-[24px] sm:text-[20px] font-normal leading-tight">
                Dive into{" "}
              </span>
              <span className="text-primaryColor lg:text-[24px] md:text-[24px] sm:text-[20px] font-normal leading-tight">
                EV Excellence
              </span>
              <span className="text-neutral-900 lg:text-[24px] md:text-[24px] sm:text-[20px] font-normal leading-tight">
                {" "}
                with our exceptional{" "}
              </span>
              <span className="text-primaryColor lg:text-[24px] md:text-[24px] sm:text-[20px] font-normal leading-tight">
                Fleet Management
              </span>
            </p>
            <button
              onClick={handleOnPress}
              className="w-40 h-[58px] px-6 py-4 bg-primaryColor rounded-lg justify-center items-center gap-2.5"
            >
              <div className="text-white lg:text-[22px] md:text-[20px] mb-[25px] sm:text-[16px] leading-tight">
                Get Started
              </div>
            </button>
          </div>
          <div className="flex h-auto lg:justify-center lg:items-center ">
            <LaptopSvg />
          </div>
        </div>
        <div className="flex justify-center overflow-hidden px-[20px]">
          <div className="lg:w-[1168px] mb-[2.5rem] text-center">
            <span className="text-neutral-900 lg:text-5xl md:text-4xl sm:text-3xl font-bold leading-tight">
              Instant Insight with the Power of{" "}
            </span>
            <span className="text-primaryColor lg:text-5xl md:text-4xl sm:text-3xl font-bold leading-tight">
              Real-Time
            </span>
            <span className="text-neutral-900 lg:text-5xl md:text-4xl sm:text-3xl font-bold leading-tight">
              {" "}
              Data in <br />
            </span>
            <span className="text-primaryColor lg:text-5xl md:text-4xl sm:text-3xl font-bold leading-tight">
              EV Charging
            </span>
            <span className="text-neutral-900 lg:text-5xl md:text-4xl sm:text-3xl font-bold leading-tight">
              {" "}
              Experience
            </span>
          </div>
        </div>
        <div>
          <DashboardSvg className="lg:w-[1259px] md:w-[629.5px] mb-[20px] sm:[419.6px] lg:h-[830px] md:h-[415px] sm:h-[276.6px]" />
        </div>

        <div className="w-[100%] lg:py-[100px] h-auto py-[20px] px-[20px] flex media-flexDirection bg-neutral-800">
          <div className="media-width lg:mx-[80px] media-textAlignment flex-col items-start">
            <h1 className="media-width font-bold lg:w-[500px] media-heading-size">
              <span className="text-white lg:text-[64px] md:text-[60px] sm:text-[52px] font-bold leading-tight">
                Elevate Your EV Experience with{" "}
              </span>
              <span className="text-primaryColor lg:text-[64px] md:text-[60px] sm:text-[52px] font-bold leading-tight">
                Verte Charge
              </span>
              <span className="text-white lg:text-[64px] md:text-[60px] sm:text-[52px] font-bold leading-tight">
                {" "}
                User Preferences
              </span>
            </h1>
            <div className="pb-[25px] pt-[20px]">
              <span className="text-white lg:text-[24px] md:text-[24px] sm:text-[20px] font-normal leading-tight">
                Personalize Your Power & Drive Your Way with{" "}
              </span>
              <span className="text-primaryColor lg:text-[24px] md:text-[24px] sm:text-[20px] font-normal leading-tight">
                Verte Charge's{" "}
              </span>
              <span className="text-white lg:text-[24px] md:text-[24px] sm:text-[20px] font-normal leading-tight">
                {" "}
                User Preferences
              </span>
            </div>
            <button
              onClick={handleOnPress}
              className="px-6 py-4 bg-primaryColor rounded-lg justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-white lg:text-[22px]  md:text-[20px] sm:text-[16px] font-medium ">
                Get Started
              </div>
            </button>
          </div>
          <div className="flex media-justifyCenter">
            <div className=" lg:w-[618.90px] mx-4 h-[auto] xl:ml-[80px] mt-6 lg:left-[726px] rounded-[50.7px]">
              <img
                className="lg:w-[560px] lg:h-[470px] pb-[20px] w-[400px] h-[auto] pt-[8px] rounded-[50.7px]"
                src={campaign}
                alt="Campaign"
              />
            </div>
          </div>
        </div>

        <div class="flex media-flexDirection1 w-[100%] h-auto py-[20px]">
          <div className="flex media-justifyCenter1">
            <div className="media-paddingt lg:pl-[100px]">
              <GraphSvg className="w-[450px] h-[400px]" />
            </div>
          </div>
          <div className="media-width1 px-[20px] lg:mr-[40px] media-textAlignment1 media-paddingt1">
            <div className="w-[100%] font-bold media-heading-size">
              <span className="text-neutral-900 lg:text-[64px]  md:text-[60px] sm:text-[52px] font-bold leading-tight">
                Uncover Insights & Optimize Performance with{" "}
              </span>
              <span className="text-primaryColor lg:text-[64px]  md:text-[60px] sm:text-[52px] font-bold leading-tight">
                Verte Charge Station Analytics
              </span>
            </div>
            <div className="w-[100%] py-[25px]">
              <span className="text-neutral-900 lg:text-[24px] md:text-[24px] sm:text-[20px] leading-tight">
                Data-driven Excellence for{" "}
              </span>
              <span className="text-primaryColor lg:text-[24px] md:text-[24px] sm:text-[20px] leading-tight">
                EV Charging Stations
              </span>
              <span className="text-neutral-900 lg:text-[24px] md:text-[24px] sm:text-[20px] leading-tight">
                {" "}
                Elevate Efficiency, Enhance User Experience
              </span>
            </div>
            <button
              onClick={handleOnPress}
              className="w-40 h-[58px] px-6 py-4 bg-primaryColor rounded-lg justify-center items-center gap-2.5"
            >
              <div className="text-white lg:text-[22px] md:text-[20px] mb-[25px] sm:text-[16px] leading-tight">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="w-[100%] h-auto lg:px-[80px] lg:py-[100px] py-[20px] bg-primaryColor flex media-flexDirection2 gap-between">
          <div className="media-textAlignment2 flex-col">
            <h1 className="media-width2 w-screen font-bold media-heading-size">
              <span className="text-neutral-900 lg:text-[64px] md:text-[60px] sm:text-[52px] font-bold leading-tight">
                Fortify Trust with{" "}
              </span>
              <span className="text-white lg:text-[64px] md:text-[60px] sm:text-[52px] font-bold leading-tight">
                Verte Charge's
              </span>
              <span className="text-neutral-900 lg:text-[64px] md:text-[60px] sm:text-[52px] font-bold leading-tight">
                {" "}
                Advanced{" "}
              </span>
              <span className="text-white lg:text-[64px]  md:text-[60px] sm:text-[52px] font-bold leading-tight">
                Security Features
              </span>
            </h1>
            <div class="py-[25px]">
              <span className="text-neutral-900 lg:text-[24px] md:text-[24px] sm:text-[20px] leading-tight ">
                Your{" "}
              </span>
              <span className="text-white lg:text-[24px] md:text-[24px] sm:text-[20px] leading-tight">
                EV Charging
              </span>
              <span className="text-neutral-900 lg:text-[24px] md:text-[24px] sm:text-[20px] leading-tight">
                {" "}
                Experience,{" "}
              </span>
              <span className="text-white lg:text-[24px] md:text-[24px] sm:text-[20px] leading-tight">
                Secured
              </span>
              <span className="text-neutral-900 lg:text-[24px] md:text-[24px] sm:text-[20px] leading-tight">
                {" "}
                at Every Connection
              </span>
            </div>
          </div>
          <div className="flex media-justifyCenter2">
            <div class="flex w-auto h-auto bg-white rounded-[50.68px] flex-col items-center my-4 py-4">
              <div class="lg:mt-[30px] md:mt-[20px] sm:mt-[15px] flex justify-center items-center">
                <img
                  src={lock}
                  alt="Lock"
                  className="lg:w-[300px] lg:h-[300px] w-[250px] h-[250px]"
                />
              </div>
              <button
                onClick={handleOnPress}
                class="lg:w-[374px] w-[280px] mx-[24px] lg:h-[86.82px] h-[70px] px-[50.30px] py-[30.41px] bg-primaryColor rounded-[25.34px] flex justify-center items-center"
              >
                <div class="text-white lg:text-[22px] md:text-[20px] sm:text-[16px] ">
                  Get Started
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FleetManagement;
