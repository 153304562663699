import React from "react";

function FacebookSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 40 39"
      fill="none"
    >
      <path
        d="M23.4322 22.425H28.3072L30.2572 14.625H23.4322V10.725C23.4322 8.7165 23.4322 6.825 27.3322 6.825H30.2572V0.273C29.6215 0.18915 27.2211 0 24.6861 0C19.3918 0 15.6322 3.23115 15.6322 9.165V14.625H9.78223V22.425H15.6322V39H23.4322V22.425Z"
        fill="white"
      />
    </svg>
  );
}

export default FacebookSvg;
